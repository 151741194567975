<template>
  <div>
    <div
      class="flex-start ph-3x pv-2x player-index"
      v-if="
        (appointmentSelection != 2 && visibleItem.timeShareAppointmentRuleId) ||
          wrapItem.fast === 1 ||
          wrapItem.whetherRecommend ||
          wrapItem.requiredPersonId ||
          (item.visitorFieldList && item.visitorFieldList.length)
      "
    >
      <div class="font-xl flex-between bold">
        <div>游客{{ item.index + 1 }}</div>
      </div>
      <div class="flex-start">
        <!-- 删除游客 -->
        <span class="iconfont ml-2x gray font-xxxl" @click="delItem(item, 0)"
          >&#xe608;</span
        >
      </div>
    </div>
    <!-- 其他信息 日期和预约时段 -->
    <template
      v-if="
        appointmentSelection == 1 &&
          (item.timeShareAppointmentRuleId ||
            wrapItem.timeShareAppointmentRuleId)
      "
    >
      <!-- 预约日期 -->
      <div class="flex-start form-item-box pl-3x pv-1x">
        <div class="flex-start" style="width:120px">
          <span class="red">*</span>
          <div class="label">
            预约日期
          </div>
        </div>
        <!-- 加购预约日期&时段-->
        <div class="flex-start">
          <a-input
            ref="userNameInput"
            v-model="visibleItem.timeName"
            placeholder="选择预约时间"
            readOnly
            size="large"
            style="width:24vw"
            @click="visibleOpen(item, 0)"
          >
            <span slot="suffix" class="iconfont" style="color: rgba(0,0,0,.45)"
              >&#xe669;</span
            >
          </a-input>
        </div>
      </div>
    </template>
    <!-- 游客信息 -->
    <a-form-model
      ref="formData"
      layout="inline"
      :model="item"
      class="flex-start flex-wrap ml-3x"
    >
      <div v-for="(temp, idx) in item.visitorFieldList" :key="temp.id">
        <!-- 4, 姓名 10司机姓名 11司机车牌号 12地址 14微信号 17台胞证 18港澳通行证 19护照 21社保卡-->
        <a-form-model-item
          v-if="[4, 10, 11, 12, 14, 17, 18, 19, 21].includes(temp.fieldType)"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: temp.fieldName,
              trigger: 'blur'
            }
          ]"
        >
          <a-input
            size="large"
            v-model="temp.fieldValue"
            :placeholder="temp.fieldName"
            allow-clear
            @focus="idcardFocus"
            @blur="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
            style="width:24vw"
          />
        </a-form-model-item>
        <!-- 5手机号 -->
        <a-form-model-item
          v-if="temp.fieldType === 5"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: `请输入${temp.fieldName}`,
              trigger: 'blur'
            },
            {
              validator: checkPhone
            }
          ]"
        >
          <a-input
            size="large"
            v-model="temp.fieldValue"
            :placeholder="temp.fieldName"
            allow-clear
            @focus="idcardFocus"
            @blur="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
            style="width:24vw"
          />
        </a-form-model-item>
        <!-- 6身份证 -->
        <a-form-model-item
          v-if="temp.fieldType === 6"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: `请输入${temp.fieldName}`,
              trigger: 'blur'
            },
            {
              validator: checkIdCard
            }
          ]"
        >
          <IdCardInput
            size="large"
            @idcardFocus="idcardFocus"
            @idcardBlur="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
            @readCard="readCard($event, item)"
            v-model="temp.fieldValue"
            class="mb-1x"
            style="width:24vw"
          ></IdCardInput>
        </a-form-model-item>
        <!-- 7生日 -->
        <a-form-model-item
          v-if="temp.fieldType === 7"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: `请选择${temp.fieldName}`,
              trigger: 'blur'
            }
          ]"
        >
          <a-date-picker
            v-model="temp.fieldValue"
            size="large"
            type="date"
            :disabled-date="disabledDate"
            @change="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
            style="width: 100%;"
            format="YYYY-MM-DD"
          />
        </a-form-model-item>
        <!-- 8性别 16国家-->
        <a-form-model-item
          v-if="[8, 16].includes(temp.fieldType)"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: `请选择${temp.fieldName}`,
              trigger: 'blur'
            }
          ]"
        >
          <a-select
            v-model="temp.fieldValue"
            :placeholder="temp.fieldName"
            size="large"
            style="width:24vw"
            @change="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
          >
            <a-select-option
              v-for="(ite, findex) in temp.fieldOptions"
              :key="findex"
              :value="ite"
            >
              {{ ite }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 9证件照 -->
        <a-form-model-item
          v-if="temp.fieldType === 9"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          class="flex-center"
          :rules="[
            {
              required: temp.required,
              message: `请选择${temp.fieldName}`,
              trigger: 'blur'
            }
          ]"
        >
          <picture-input class="m-x" v-model="temp.fieldValue"></picture-input>
        </a-form-model-item>
        <!-- 13邮箱 -->
        <a-form-model-item
          v-if="temp.fieldType === 13"
          :prop="`visitorFieldList[${idx}].fieldValue`"
          :label="temp.fieldName"
          labelAlign="left"
          :colon="false"
          :rules="[
            {
              required: temp.required,
              message: `请输入${temp.fieldName}`,
              trigger: 'blur'
            },
            {
              validator: checkEmail
            }
          ]"
        >
          <a-input
            size="large"
            v-model="temp.fieldValue"
            :placeholder="temp.fieldName"
            allow-clear
            @focus="idcardFocus"
            @blur="
              changePlayer(item, `visitorFieldList[${idx}].fieldValue`, {})
            "
            style="width:24vw"
          />
        </a-form-model-item>
      </div>
      <!-- 人脸 -->
      <a-form-model-item
        v-if="item.requiredPersonId"
        :prop="`personId`"
        label="人脸"
        labelAlign="left"
        :colon="false"
        class="flex-center"
        :rules="[
          {
            required: item.requiredPersonTrue,
            message: `请录入人脸`,
            trigger: 'blur'
          }
        ]"
      >
        <face-input
          :key="Math.random()"
          size="'mini'"
          @imgUrlChange="res => faceChange(item, res)"
          :echoimgurl="item.personFaceUrl"
        ></face-input>
      </a-form-model-item>
    </a-form-model>
    <!-- 加购 -->
    <!-- 快速通行服务 -->
    <template>
      <div class="form-item-box">
        <div class="mv-2x pl-3x">
          <div class="flex-between">
            <div class="flex-center">
              <div v-if="wrapItem.whetherRecommend">
                <span class="font-s">加购产品</span>
                <a-button
                  @click="toAdd(wrapItem.productId)"
                  class="ml-2x ph-3x"
                  type="primary"
                  shape="round"
                >
                  选择
                </a-button>
              </div>
            </div>
            <div>
              <a-checkbox
                v-if="wrapItem.fast === 1"
                :checked="wrapItem.whetherFastTicket"
                @change="fastChange(item, '')"
                class="font-l gray"
              >
                快速通行服务
                <span class="ml-2x">{{ wrapItem.fastChannelFee | money }}</span>
              </a-checkbox>
            </div>
          </div>
          <template v-if="wrapItem.whetherRecommend">
            <div v-for="temp in item.subProductList" :key="temp.productId">
              <!-- 产品一行 -->
              <div class="flex-between">
                <div class="flex-start pv-1x">
                  <!-- 是否选中 -->
                  <a-checkbox
                    @change="checkAddBuy(wrapItem, temp, item)"
                    :checked="temp.productFlagPO.whetherCheck === 1"
                    class="flex-start"
                  >
                    <p class="bold">{{ temp.productName }}</p>
                  </a-checkbox>
                  <p class="price ml-2x">
                    {{ temp.sellingAmount | money }}
                  </p>
                </div>
                <!-- 加减数量 -->
                <div class="flex-start mr-3x">
                  <!-- 减 -->
                  <span
                    v-if="temp.productFlagPO.whetheChangeNum"
                    class="iconfont font-xxxl gray"
                    @click="minusAddBuy(temp, item)"
                    >&#xe748;</span
                  >
                  <div class="flex-center gray" style="width:40px">
                    <span v-if="!temp.productFlagPO.whetheChangeNum">x </span>
                    <span>{{ temp.productFlagPO.buyNum }}</span>
                  </div>
                  <!-- 加 -->
                  <span
                    class="iconfont primary font-xxxl"
                    v-if="temp.productFlagPO.whetheChangeNum"
                    @click="plusAddBuy(temp, item)"
                    >&#xe749;</span
                  >
                  <span class="icon iconfont" v-else></span>
                  <!-- 删除加购 -->
                  <span
                    v-if="temp.productFlagPO.whetheChangeNum"
                    class="iconfont bold red font-l ml-3x"
                    @click="delAddBuy(temp, item)"
                    >&#xe622;</span
                  >
                  <span v-else class="icon iconfont minus ml-3x"></span>
                </div>
              </div>
              <!-- 加购预约日期&时段 -->
              <div class="flex-start pl-3x" v-if="temp.appointmentTimePO">
                <a-input
                  ref="userNameInput"
                  :defaultValue="getTimeName(temp)"
                  placeholder="选择预约时间"
                  readOnly
                  style="width:30%"
                  @click="visibleOpen(temp, 1)"
                >
                  <span
                    slot="suffix"
                    class="iconfont"
                    style="color: rgba(0,0,0,.45)"
                    >&#xe669;</span
                  >
                </a-input>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- 预约时间弹框 -->
    <SelectDateAndTime
      ref="SelectDateAndTime"
      :visible.sync="visible"
      :params="params"
      :item="item"
      @timeOk="visibleOpenOk"
    />
    <!-- 加购弹框 -->
    <AddBuy
      ref="AddBuy"
      :addVisible.sync="addVisible"
      :params="params"
      @ok="updateMoreList"
    />
  </div>
</template>
<script>
import { selectDiscountSku } from "@/api/cart";
import { fileUploadBase64 } from "@/api/upload";
import SelectDateAndTime from "./SelectDateAndTime.vue";
import AddBuy from "./AddBuy.vue";
import PictureInput from "@/components/hardware/picture-input.vue";
import FaceInput from "@/components/hardware/face-input";
import IdCardInput from "@/components/hardware/id-card-input";
import { checkPhone, checkIdCard } from "@/utils/global";
import moment from "moment";
export default {
  name: "BasePlayer",
  components: {
    SelectDateAndTime,
    AddBuy,
    PictureInput,
    FaceInput,
    IdCardInput
  },
  provide() {
    return {
      validateConfirm: this.validateConfirm //购物车游客信息校验 inject: ["validateConfirm"],this.validateConfirm()直接使用
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    // 子产品时的主产品
    wrapItem: {
      type: Object,
      default() {
        return {};
      }
    },
    cartId: {
      type: Number,
      default: 0
    },
    // 是否子产品
    isSubProduct: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "", // 分类id
          appointmentSelection: "" //分时预约选择 1.产品  2.子景区 3.景区
        };
      }
    }
  },
  data() {
    return {
      checkPhone,
      checkIdCard,
      fieldFisible: false,
      openPopupIndex: 0, //当前点击游客信息
      fieldOptions: [], //点击游客信息选项

      voucherVisible: false,
      voucherIndex: 0,

      visible: false, //预约日期时段弹框
      addVisible: false, //加购弹窗
      isSub: false, //子产品选择游客
      productItem: {}, //当前选择的子产品
      addTourist: [], //已选的游客
      visibleItem: {
        timeName: "",
        timeOpen: false,
        playDate: "",
        timeIntervalId: "",
        beginTime: "",
        endTime: "",
        times: [],
        timeShareAppointmentRuleId: ""
      }, //临时修改预约日期和时段
      date: "",
      minDate: "",
      addProduct: {} //当前加购产品
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return "";
    }
  },
  created() {
    console.log("item", this.item, this.wrapItem);
    this.appointmentSelection = sessionStorage.getItem("appointmentSelection");
  },
  methods: {
    moment,
    idcardFocus() {
      this.$emit("onFocus");
    },
    // 删除购物车数量
    delItem(item, val) {
      this.$emit("del-item", item, val);
    },
    // 批量选择加购
    updateMoreList(arr) {
      this.addVisible = false;
      if (arr && arr.length) {
        const addBuyList = arr.map(temp => {
          return {
            addBuyProductId: temp,
            addBuyNum: 1
          };
        });
        let obj = {};
        // 组合产品：一子产品一游客需要subIndex和subProductId
        if (this.isSubProduct || obj) {
          obj = {
            productId: this.wrapItem.productId,
            index: this.wrapItem.index,
            subProductId: this.item.productId,
            subIndex: this.item.index,
            addBuyList: addBuyList
          };
        } else {
          obj = {
            productId: this.wrapItem.productId,
            index: this.wrapItem.index,
            addBuyList: addBuyList
          };
        }
        this.$emit("update-add-buy-batch", obj); //批量加购
      }
    },
    // 快速通行
    fastChange(item, obj) {
      if (this.isSubProduct) {
        obj = item;
        item = this.wrapItem;
      }
      const data = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index
      };
      // 一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
        data.whetherFastTicket = obj.whetherFastTicket ? 0 : 1;
      }
      // 一种产品一游客、、票务
      else {
        data.whetherFastTicket = item.whetherFastTicket ? 0 : 1;
      }
      this.$emit("update-fast", data);
    },
    /**
     * 加购start
     **/
    // 选中加购
    checkAddBuy(item, temp, obj) {
      const val = temp.productFlagPO.whetherCheck ? 0 : 1;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          whetherCheck: val
        },
        obj
      );
    },
    // 加购加
    plusAddBuy(temp, item) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        addBuyNum: temp.productFlagPO.buyNum + 1
      });
    },
    // 加购减
    minusAddBuy(temp, item) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (temp.productFlagPO.buyNum > 0)
        this.updateAddBuy({
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: temp.productFlagPO.buyNum - 1
        });
    },
    delAddBuy(temp, item) {
      this.updateAddBuy({
        productId: item.productId,
        index: item.index,
        addBuyProductId: temp.productId,
        addBuyNum: 0
      });
    },
    // 更新加购
    updateAddBuy(data, obj) {
      // 如果是组合产品：一子产品一游客
      if (this.isSubProduct || obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
      }
      this.$emit("update-add-buy", data);
    },
    // 加购end
    /**
     * 身份证回调
     * res: 身份信息
     * */
    readCard(res, val) {
      if (res) {
        val.visitorFieldList.forEach(item => {
          //填充姓名
          if (item.fieldType == 4) {
            item.fieldValue = res.name;
          } else if (item.fieldType == 7) {
            item.fieldValue = res.birth;
          } else if (item.fieldType == 8) {
            //性别(2男，1女)
            if (res.sex === "男") {
              item.fieldValue = res.sex;
            } else {
              item.fieldValue = "女";
            }
          } else if (item.fieldType == 9) {
            this.isReadCard = true;
            //证件照
            fileUploadBase64({ data: res.photo, ext: "png" })
              .then(value => {
                this.$set(item, "fieldValue", value.src);
                // if (val.requiredPersonId) {
                //   // 如果有人脸
                //   this.getPicture(res.photo, val, key, temp, valItem);
                // } else {
                //   if (!valItem) {
                //     this.isReadCard = false;
                //     this.changePlayer(val, key, temp);
                //   } else {
                //     // 一子产品一游客
                //     this.isReadCard = false;
                //     this.changePlayer(valItem, key, temp, val);
                //   }
                // }
              })
              .catch(() => {
                this.isReadCard = false;
                this.$message.error("图片上传接口错误");
              });
          } else if (item.fieldType == 12) {
            // 地址
            item.fieldValue = res.address;
          }
        });
      }
    },
    // 快速匹配下单
    quickAddCart(res) {
      console.log(res);
      if (!res.id) {
        return;
      }
      this.$emit("quickAddCart", res);
    },
    /**
     * 人脸录入
     * item: 主产品行
     * res: 图片地址
     * obj: 子产品行
     * */
    faceChange(item, res, obj) {
      if (obj) obj.personFaceUrl = res;
      else item.personFaceUrl = res;
      this.getTicketTraveller(item, obj);
    },
    // 生日日期禁用
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 日期&时段
    getTimeName(temp) {
      return `${
        temp.playDate ? moment(temp.playDate).format("yyyy-MM-DD") : ""
      } ${
        temp.appointmentTimePO
          ? moment(temp.appointmentTimePO.appointmentBeginTime).format("hh:mm")
          : ""
      }~${
        temp.appointmentTimePO
          ? moment(temp.appointmentTimePO.appointmentEndTime).format("hh:mm")
          : ""
      }`;
    },
    // 预约时间时段确认
    visibleOpenOk(timeObj) {
      this.visible = !this.visible;
      if (this.addProduct) {
        // 加购的日期时段
        this.addProduct.playDate = timeObj.playDate;
        this.addProduct.times = [];
        this.addProduct.timeIntervalId = timeObj.timeId;
        this.updateAddBuy(
          {
            productId: this.item.productId,
            index: this.item.index,
            addBuyProductId: this.addProduct.productId,
            timeShareAppointmentRuleId: this.addProduct
              .timeShareAppointmentRuleId,
            timeIntervalId: this.addProduct.timeIntervalId,
            playDate: this.addProduct.playDate
          },
          this.addProduct
        );
      } else {
        // 产品的
        let item = this.item;
        let obj = {};
        this.visibleItem.beginTime = timeObj.beginTime;
        this.visibleItem.endTime = timeObj.endTime;
        this.visibleItem.playDate = timeObj.playDate;
        this.visibleItem.timeName = `${
          this.visibleItem.playDate
            ? moment(this.visibleItem.playDate).format("yyyy-MM-DD")
            : ""
        } ${
          this.visibleItem.beginTime
            ? moment(this.visibleItem.beginTime).format("hh:mm")
            : ""
        }~${
          this.visibleItem.endTime
            ? moment(this.visibleItem.endTime).format("hh:mm")
            : ""
        }`;
        if (this.isSubProduct) {
          item = this.wrapItem;
          obj = this.item;
        }
        // 日期
        let data = {
          cartId: this.cartId,
          playDate: timeObj.playDate,
          productId: item.productId,
          index: item.index
        };
        // 子产品
        if (obj) {
          data.subProductId = obj.productId;
          data.subIndex = obj.index;
          obj.timeOpen = false;
        }
        let time = { ...data }; //时段
        delete time.playDate;
        time.timeIntervalId = timeObj.timeId;
        this.$emit("update-date", data, time); //更新日期时段
      }
    },
    // 打开选择时段 type 0产品 1加购
    visibleOpen(temp, type) {
      this.visible = true;
      this.addProduct = "";
      if (type) {
        this.addProduct = temp;
      }
      if (!temp.timeShareAppointmentRuleId) {
        temp.timeShareAppointmentRuleId = this.wrapItem.timeShareAppointmentRuleId;
      }
      this.$refs.SelectDateAndTime.open(temp.timeShareAppointmentRuleId);
    },
    //最终下单时校验
    validateConfirm() {
      let err = ""; //空的时候无问题，有则返回校验信息
      this.$refs.formData.validate(valid => {
        err = valid;
      });
      return err;
    },
    changePlayer(item, key, val = {}, temp) {
      this.idcardFocus();
      if (this.isSubProduct) {
        temp = item;
        item = this.wrapItem;
      }
      if (this.isReadCard) return;
      this.$refs.formData.validateField(key, valid => {
        if (!valid) {
          this.discountSku = {};
          this.changePlayerItem = item;
          // 如果是身份证情况下，且已选择时段，则进行惠民弹框
          if (
            val.fieldType === 6 &&
            val.fieldValue.trim() &&
            item.appointmentTimePO &&
            item.appointmentTimePO.appointmentBeginTime
          ) {
            let obj = {
              playDate: this.params.playDate, // 游玩日期
              idCard: val.fieldValue, // 身份证号
              timeIntervalId: item.timeIntervalId, // 时段id
              timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
              //   // 多规格 item.specCombination === 1, 否则单规格
              productId: item.productId, // 产品id
              spuId: item.spuId, // 产品spuld
              subScenicId: this.params.subScenicId //子景区id 特惠产品要用到
            };
            selectDiscountSku(obj).then(res => {
              if (res.selectProduct && res.selectProduct.length > 1) {
                // 惠民列表产品大于1，提供选择弹框
                //选择特惠产品
                this.discountSkus = res.selectProduct;
                this.discountSku = res.selectProduct[0];
                this.showDiscountSku = true;
              } else if (res.selectProduct && res.selectProduct.length === 1) {
                // 惠民列表为1不弹框选中
                this.discountSku = res.selectProduct[0];
                this.getTicketTraveller(item, temp);
              } else {
                // 是身份证情况下，没惠民政策
                this.getTicketTraveller(item, temp);
              }
            });
          } else {
            // 非填写身份证情况下
            this.getTicketTraveller(item, temp);
          }
        }
      });
      window.readIdCard = this.quickAddCart; //把身份证回调切回快速匹配
    },
    // 更新游客信息
    getTicketTraveller(item, temp) {
      // 把凭证信息复制到更多游客信息
      let obj = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
        traveler: this.getTraveler(temp || item) // temp 为组合子产品行，item为主产品行
      };
      // 惠民政策
      if (this.discountSku.selectProductSkuId) {
        obj.discountProductId = this.discountSku.selectProductSkuId;
      }
      // 如果是组合产品 一子产品一游客时
      if (temp) {
        obj.subProductId = temp.productId;
        obj.subIndex = temp.index;
      }
      this.$emit("update-player", obj);
    },
    // 获取游客信息
    getTraveler(item) {
      const obj = {
        visitorFieldList: item.visitorFieldList.map(temp => {
          return {
            id: temp.id,
            fieldType: temp.fieldType,
            fieldValue: temp.fieldValue
          };
        })
      };
      // 如果存在证件类型列表
      if (item.IdTypeList && item.IdTypeList.length) {
        const arr = [];
        item.IdTypeList.forEach(temp => {
          // 如果凭证名称或证件类型选择了指定证件，则录入证件后到购物车，否则不录入
          if (temp.fieldType === item.IdType) {
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: temp.fieldValue
            });
          } else {
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: ""
            });
          }
        });
        obj.visitorFieldList = obj.visitorFieldList.concat(arr);
      }
      // 游客类型
      if (item.visitorTypeId) obj.visitorTypeId = item.visitorTypeId;
      // 添加卡腕带号
      if (item.requiredVoucherNo) {
        // 为0不开启卡腕带录入
        obj.voucherNo = item.voucherNo;
      }
      // 添加人脸
      if (item.requiredVoucherNo) {
        // 为1不开启人脸录入
        obj.personId = item.personId;
        obj.personFaceUrl = item.personFaceUrl;
      }
      return obj;
    },
    openPlayDate() {},
    toAdd(productId) {
      this.$refs.AddBuy.open(productId);
      this.addVisible = true;
    }
  }
};
</script>
<style scoped lang="less">
/deep/.ant-form-item-label {
  width: 120px;
  label {
    color: #7e7e7e;
  }
}
.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
</style>
