<!--
  @name: 证件照或者拍照直接上次组件
  @author: fengyanlong
  @date:2021-05-13
  @attr: {
    //返回照片地址
    v-model:{
      type: String
    }
  }
-->

<template>
  <div class="pictureInput" @click="open">
    <div v-if="value" class="picture">
      <img :src="value" alt="图片显示失败" />
    </div>
    <div v-else>
      <a-icon type="camera" class="icon" />
    </div>
  </div>
</template>

<script>
import { fileUploadBase64 } from "../../api/upload";

export default {
  name: "picture-input",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    open() {
      try {
        window.getPicture = this.getPicture;
        window.test.openCamera();
      } catch (error) {
        this.$warning("开启失败，请检查摄像头设备");
      }
    },
    getPicture(data64) {
      fileUploadBase64({ data: data64, ext: "png" })
        .then(res => {
          this.value = res.src;
          this.$emit("input", res.src);
        })
        .catch(err => {
          console.log(err);
          this.$error("接口错误");
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.pictureInput {
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    font-size: 40px;
    color: @gray;
  }
}
</style>
