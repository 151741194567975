<!-- 加购弹窗 -->
<template>
  <div>
    <a-modal
      centered
      :visible="addVisible"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
      width="70%"
    >
      <h3 class="flex-center bold bd-b pb-2x">加购产品</h3>
      <!-- content -->
      <div>
        <a-checkbox
          :checked="checkAll"
          @change="onCheckAllChange"
          class="bd-b pv-2x res"
          >全选</a-checkbox
        >
        <a-checkbox-group v-model="form.values" class="res">
          <div
            v-for="item in moreList"
            :key="item.id"
            class="bd-b pv-2x font-xl flex-between"
          >
            <a-checkbox :value="item.id" :checked="item.checked === 1"
              >{{ item.skuName }}
            </a-checkbox>
            <span class="price">{{ item.price | money }}</span>
          </div>
        </a-checkbox-group>
      </div>
      <div>
        <div class="flex-center pt-3x bd-t">
          <a-button
            @click="handleCancel"
            size="large"
            class="ml-2x mybtn"
            shape="round"
          >
            取消
          </a-button>
          <a-button
            class="ml-2x mybtn"
            size="large"
            type="primary"
            shape="round"
            @click="handleOk"
          >
            确定
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { ticketAddBuy } from "@/api/cart";
export default {
  name: "AddBuy",
  components: {},
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    // productId: {
    //   type: String,
    //   default: ""
    // },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "", // 分类id
          appointmentSelection: "", //分时预约选择 1.产品  2.子景区 3.景区
          visible: false //预约日期时段弹框
        };
      }
    }
  },
  data() {
    return {
      moreList: [],
      form: { values: [] },
      checkAll: false, //是否全选
      productId: ""
    };
  },
  created() {},
  methods: {
    open(productId) {
      this.productId = productId;
      this.getList();
    },
    handleOk() {
      this.$emit("ok", this.form.values);
    },
    onCheckAllChange(e) {
      this.form.values = e.target.checked
        ? this.moreList.map(ite => ite.id)
        : [];
      this.checkAll = e.target.checked;
    },
    handleCancel() {
      this.$emit("update:addVisible", false);
    },
    getList() {
      ticketAddBuy({
        productId: this.productId,
        dailyCalendar: this.params.playDate
      }).then(res => {
        this.form.values = res
          .filter(ite => ite.checkDefault)
          .map(ite => ite.id);
        res.forEach(ite => (ite.checked = ite.checkDefault));
        this.moreList = res;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.time-box {
  border-radius: 6px;
}
.mybtn {
  padding: 0 70px;
}
.price {
  color: #ff2d2d;
}
</style>
