<!-- 出游人组件 -->
<template>
  <div class="font-l mb-3x ">
    <!--字段类型：1文本,2日期,3下拉选择,4姓名,5手机,6身份证,7生日,8性别,9证件照,10司机姓名,
          11司机车牌号,12地址,13邮箱,14微信openId,15证件类型,16国家,17台胞证,18港澳通行证 19护照
        -->
    <template v-for="(item, index) in formData.cartList">
      <div :key="index" class="product">
        <!--子产品 使用规则如果是一子产品一游客 combUseRule === 2-->
        <template v-if="item.combUseRule === 2">
          <div
            class="sub-product"
            v-for="(obj, inde) in item.subProductList"
            :key="inde"
          >
            <div class="ticket-list-item">
              <div class="res pl-3x pt-3x flex-start">
                <div class="flex-start">
                  <p class="font-xl bold text-1 font-l">
                    {{ obj.productName
                    }}<span v-if="obj.whetherSpecialDiscount" class="hui"
                      >惠</span
                    >
                  </p>
                  <div class="ml-1x" v-if="obj.buyNum > 1">
                    x{{ obj.buyNum }}
                  </div>

                  <b class="price bold ml-2x">¥ {{ obj.sellingAmount }}</b>
                </div>
                <span class="blue ml-3x ph-1x pv-x font-m">{{
                  obj.ownerMerchantName
                }}</span>
              </div>
            </div>
            <BasePlayer
              :ref="'basePlayer' + inde"
              :item="obj"
              :wrapItem="item"
              :cartId="cartId"
              :isSubProduct="true"
              :params="params"
              @update-player="updatePlayer"
              @update-add-buy="changeAddBuy"
              @update-add-buy-batch="updateAddBuyBatch"
              @update-fast="updateFast"
              @update-date="updateDate"
              @quickAddCart="quickAddCart"
              @del-item="delItem"
            />
          </div>
        </template>
        <!--和票务一样 使用规则一种产品一游客-->
        <template v-else>
          <BasePlayer
            :ref="'basePlayer' + index"
            :item="item"
            :wrapItem="item"
            :cartId="cartId"
            :params="params"
            @update-player="updatePlayer"
            @update-add-buy="changeAddBuy"
            @update-add-buy-batch="updateAddBuyBatch"
            @update-fast="updateFast"
            @update-date="updateDate"
            @quickAddCart="quickAddCart"
            @del-item="delItem"
            @onFocus="onFocus"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import BasePlayer from "./BasePlayer.vue";
export default {
  name: "ProductPlayer",
  components: {
    BasePlayer
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    // 子产品时的主产品
    wrapItem: {
      type: Object,
      default() {
        return {};
      }
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "" // 分类id
        };
      }
    }
  },
  data() {
    return {
      // checkEmail,
      aurl: [],
      showPicker: false,
      humanFace: [],
      cartId: 0,
      formData: {
        cartList: []
      },
      // 加购
      visible: false,
      tempRow: {}, // 临时行数据
      addBuyList: [], // 加购产品列表
      showDiscountSku: false, // 惠民列表弹框
      discountSku: {}, // 惠民列表选中值
      discountSkus: [], //特惠产品列表
      changePlayerItem: {}, // 修改游客信息item纪录

      voucherIndex: 0 //凭证index
    };
  },
  created() {},
  methods: {
    onFocus() {
      this.$emit("onFocus");
    },
    // 删除购物车数量
    delItem(item, val) {
      const obj = {
        productId: item.productId,
        index: item.index,
        detailBuyNum: val
      };
      this.$emit("del-item", obj);
    },
    quickAddCart(cart) {
      this.$emit("quickAddCart", cart);
    },
    updateDate(date, time) {
      this.$emit("update-date", date, time);
    },
    // 批量更新加购
    updateAddBuyBatch(data) {
      this.$emit("update-add-buy-batch", data);
    },
    updateFast(data) {
      this.$emit("update-fast", data);
    },
    changeAddBuy(data) {
      this.$emit("update-add-buy", {
        ...data
      });
    },
    updatePlayer(obj) {
      this.$emit("update-player", obj);
    },
    // 购物车改动后更新
    updateCart({ detailProductList, id }) {
      this.cartId = id;
      // 处理购物车返回的数据
      const dealData = item => {
        // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
        const arr = this.appointment === 2 ? this.times : [];
        // 过滤出可用时段
        const times = arr.filter(temp => {
          return temp.remainingStock > 0 && temp.endTime > new Date().getTime();
        });
        const obj = {
          index: item.index, // 序号
          productId: item.productId,
          playDate: this.params.playDate,
          spuId: item.spuId,
          productName: item.productName, // 产品名称
          ownerMerchantName: item.ownerMerchantName, // 商户名称
          sellingAmount: item.sellingAmount / 100, // 价格
          buyNum: item.productFlagPO.buyNum, // 数量
          buyNumDiasabled: false, // 修改数量禁用
          voucherList: [], // 产品凭证
          visitorFieldList: item.traveler && item.traveler.visitorFieldList, // 游客信息
          memberTouristList: item.traveler && item.traveler.memberTouristList, // 游客类型列表
          visitorTypeId: item.traveler && item.traveler.visitorTypeId, // 选中的游客类型
          wristbandPrice: item.traveler && item.traveler.wristbandPrice, // 游客信息
          fast: item.traveler && item.traveler.fast, // 是否开启快票功能
          fixValidStartTime: item.fixValidStartTime, //固定产品有效期
          fixValidEndTime: item.fixValidEndTime, //固定产品有效期
          productFlagPO: item.productFlagPO,
          fastChannelFee: item.traveler && item.traveler.fastChannelFee, //快速下单金额
          whetherFastTicket: item.traveler && !!item.traveler.whetherFastTicket, // 是否打开了快票
          timeIntervalId: item.appointmentTimePO
            ? item.appointmentTimePO.timeIntervalId
            : "", // 选中的时段id
          playerType: "", // 游客类型
          numDisabled: false, // 修改数量禁用
          // 加购产品列表
          subProductList: item.subProductList.map(temp => {
            // 设置时段选择
            this.$set(
              temp,
              "timeIntervalId",
              temp.appointmentTimePO
                ? temp.appointmentTimePO.timeIntervalId
                : ""
            );
            this.$set(temp, "times", times);
            this.$set(temp, "timeOpen", false);
            return temp;
          }),
          whetherRecommend: item.whetherRecommend, // 是否有推荐加购
          requiredPersonId: item.traveler && item.traveler.requiredPersonId, //是否需要人脸
          requiredPersonTrue: item.traveler && item.traveler.requiredPersonTrue, //人脸是否必填
          personFaceUrl: item.traveler && item.traveler.personFaceUrl, // 人脸头像
          personId: item.traveler && item.traveler.personId, // 人脸头像id
          times, // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
          timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 产品是否开启分时预约
          timeOpen: false
        };
        // 产品凭证列表
        obj.voucherList = item.traveler && item.traveler.voucherList;
        if (item.voucherList) {
          obj.voucherList.sort(function(a, b) {
            return a.voucherType - b.voucherType;
          });
          obj.voucherType = obj.voucherList[0].voucherType; // 凭证类型, 默认选中第一个
          obj.voucherList.forEach(temp => {
            // 回显选中的凭证名称
            if (temp.voucherSelect === 1) {
              obj.voucherType = temp.voucherType;
            }

            this.$set(temp, "fieldValue", "");
            for (let val of obj.visitorFieldList) {
              if (val.fieldType === temp.voucherType) {
                temp.fieldValue = val.fieldValue;
              }
              // 回显卡腕带号到凭证信息
              if (temp.voucherType === 100) {
                temp.fieldValue = item.traveler.voucherNo;
              }
            }
          });
        }
        return obj;
      };
      this.formData.cartList = detailProductList.map(item => {
        // 如果使用规则是一子产品一游客，则游客信息放在
        if (item.productFlagPO.combUseRule === 2) {
          return {
            productId: item.productId,
            index: item.index, // 序号
            productName: item.productName, // 组合产品名称
            sellingAmount: item.sellingAmount, // 组合产品价格
            combUseRule: 2, // 一子产品一游客
            timeShareAppointmentRuleId: item.timeShareAppointmentRuleId,
            // 子产品列表
            subProductList: item.subProductList.map(temp => {
              return dealData(temp);
            }),
            whetherRecommend: item.whetherRecommend
          };
        } else {
          return dealData(item);
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
.blue {
  color: #f2a925;
  background-color: #fff8e7;
}
.hui {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  background: #f32a2a;
  color: #fff;
}
</style>
