<template>
  <div>
    <div class="qr-wrap flex-center">
      <img
        src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
        alt="qrcode"
      />
    </div>
    <p class="text-c font-32">将门票二维码对准右下方扫描器</p>
    <p class="text-c font-32 bold mt-2x">开始自助取票</p>
    <qmp-keyboard class="keyWord" v-model="keyWord" @ok="enter" />

    <!-- 打印进度弹窗 -->
    <a-modal
      :centered="true"
      :footer="false"
      v-model="printProcessDialog"
      :mask-closable="false"
    >
      <img src="../../../assets/images/print.png" alt="print-img" />
      <p class="font-xl text-c bold">{{ process }}/{{ total }}</p>
      <p class="font-xl text-c bold">请勿离开！</p>
      <p class="font-xl text-c bold">正在出票中...</p>
    </a-modal>

    <!-- 版本号-联系方式 -->
    <qmp-bottom />
  </div>
</template>

<script>
import QmpKeyboard from "../../../components/common/QmpKeyboard.vue";
import { orderSelfFetchTicket } from "../../../api/search";
import mixin from "./mixin";
import QmpBottom from "@/components/common/QmpBottom";
import { speak } from "../../../utils/hardware";
export default {
  name: "TakeIndex",

  components: { QmpKeyboard, QmpBottom },

  mixins: [mixin],

  data() {
    return {
      keyWord: "",
      process: 0, // 当前进度
      total: 0, // 出票总数
      printProcessDialog: false // 打印弹窗
    };
  },

  created() {
    this.$speak("请将取票二维码对准下方扫描器或将IC卡放置在下方读卡器");
  },

  methods: {
    enter(val) {
      orderSelfFetchTicket({ keyword: val })
        .then(res => {
          if (res.length === 0) {
            this.$message.error("未查询到相关门票");
            this.$speak("未查询到相关门票");
          } else if (res.length === 1) {
            this.takeNow(
              res.map(item => {
                return item.id;
              })
            );
          } else if (res.length > 1) {
            this.$router.push({
              path: "/take/TakeList",
              query: {
                keyword: val
              }
            });
          }
        })
        .catch(() => {});
    },
    // 打印进度
    printProgress(params) {
      // console.log(params, "---*----打印进度回传数据-");
      if (params) {
        const arr = params.split(",");
        this.total = arr[0];
        this.process = arr[1];
        if (this.process >= this.total) {
          this.timer2 = setTimeout(() => {
            speak("出票成功,请取票");
            this.printProcessDialog = false;
            clearTimeout(this.timer2);
          }, 1000);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.qr-wrap {
  img {
    width: 550px;
    min-height: 423px;
  }
}
.font-32 {
  font-size: 32px;
  line-height: 38px;
}
.keyWord {
  width: 660px;
  margin: 42px auto;
}
</style>
