<template>
  <!-- 购买首页 -->
  <QmpLayout>
    <!--业态切换-->
    <buy-tabs slot="header" v-model="format" @change="tabChange" />

    <!--时间选择-->
    <buy-time ref="buyDate" :format="format" @change="timeChange" />

    <!-- 产品分类 -->
    <ul class="categorys">
      <li
        v-for="item in categorys"
        :key="item.id"
        @click="changeCategory(item.id)"
        class="name"
        :class="{ active: item.id === params.categoryId }"
      >
        {{ item.name }}
      </li>
    </ul>

    <!--各个业态组件-->
    <component
      :is="component[format]"
      :elm="component[format]"
      :id="format"
      ref="formatComponent"
      class="comm"
    />

    <!--结算-->
    <buy-footer :cart="cart" :format="format" />
  </QmpLayout>
</template>

<script>
import BuyTabs from "./components/BuyTabs.vue";
import BuyFooter from "./components/BuyFooter.vue";
import BuyTime from "./components/BuyTime.vue";

import TicketIndex from "./ticket/TicketIndex.vue";
import CombinationIndex from "./combination/CombinationIndex.vue";
import { searchProductSelfAggregategoryt } from "../../../api/search";

export default {
  name: "BuyIndex",

  components: { TicketIndex, CombinationIndex, BuyTabs, BuyFooter, BuyTime },

  provide() {
    return {
      changeCart: this.changeCart
    };
  },
  data() {
    return {
      format: 6, //业态id
      categorys: [], // 分类数组
      categoryId: "", // 分类id
      cart: { id: "", productNum: 0, settlementPrice: 0 },
      params: {
        appointmentRules: "", // 分时预约规则id
        playDate: "", // 游玩日期
        timeId: "", // 时段id
        categoryId: "" // 分类id
      },
      // 业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      component: {
        6: "TicketIndex", //票务
        13: "CombinationIndex"
      }
    };
  },
  computed: {
    subScenicId() {
      return this.$store.state.common.saleMerchantId;
    }
  },
  created() {},

  methods: {
    // 加载产品分类
    loadCategory() {
      return searchProductSelfAggregategoryt({
        formats: this.format
      })
        .then(res => {
          this.categorys = res;
          this.params.categoryId = res?.[0]?.id;
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    // tab切换重新加载数据
    async tabChange() {
      this.params.categoryId = "";
      await this.loadCategory();
      this.$refs.buyDate.loadData();
    },

    // 回显底部购物车信息
    changeCart(cart) {
      this.cart = cart;
    },
    // 分类切换
    changeCategory(id) {
      this.params.categoryId = id;
      this.getProductData();
    },
    // 日期时段改变
    async timeChange(params) {
      for (let key in params) {
        this.params[key] = params[key];
      }
      // 首次加载，需要等分类获取完毕才可获取产品列表
      if (this.categorys.length === 0) await this.loadCategory();
      this.getProductData();
    },
    // 获取产品
    getProductData() {
      this.$refs.formatComponent.changeFilter({
        ...this.params,
        subScenicId: this.subScenicId // 子景区id
      });
    }
  }
};
</script>

<style lang="less" scoped>
.categorys {
  display: flex;
  padding: 22px 32px;
  overflow-x: auto;
  .name {
    padding: 8px 25px;
    border-radius: 26px;
    box-sizing: border-box;
    border: 1px solid transparent;
    font-size: 22px;
    color: #7e7e7e;
    cursor: pointer;

    &.active {
      border-color: #00c150;
      color: #00c150;
      background-color: #fff;
    }
  }
}
</style>
