<template>
  <div>
    <div class="qr-wrap flex-center">
      <img
        src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
        alt="qrcode"
      />
    </div>
    <!--    payType -1 支付失败，error支付异常，1支付成功-->
    <template v-if="payType !== '1'">
      <p class="text-c font-32 bold" v-if="payType === '-1'">
        支付失败，请重新扫码付款
      </p>
      <!--<p class="text-c font-32 bold" v-else-if="payType === 'error'">-->
      <!--  支付异常，请联系管理员!-->
      <!--</p>-->
      <p class="text-c font-32 bold" v-else>开始扫码付款</p>
      <p class="text-c font-32 mt-2x">将微信或支付宝支付码对准右下角扫描器</p>
    </template>
    <!--    支付状态成功-->
    <div class="pay-success" v-if="payType === '1'">
      <div class="pay-success-wrap">
        <div class="check-icon">
          <a-icon type="check" />
        </div>
        <p class="font-xxxxl">
          支付成功...
        </p>
        <p class="font-xl mt-1x">
          <span class="red">{{ timeNum }}</span>
          秒后将自动跳转返回
        </p>
        <a-button
          @click="continueSure"
          type="primary"
          size="large"
          class="btn-lg"
        >
          继续下单
        </a-button>
      </div>
    </div>

    <!-- 打印进度弹窗 -->
    <a-modal
      :centered="true"
      :footer="false"
      v-model="printProcessDialog"
      :mask-closable="false"
    >
      <img src="../../../assets/images/print.png" alt="print-img" />
      <p class="font-xl text-c bold">{{ process }}/{{ total }}</p>
      <p class="font-xl text-c bold">请勿离开！</p>
      <p class="font-xl text-c bold">正在出票中...</p>
    </a-modal>
  </div>
</template>

<script>
import {
  scanCode,
  clearScanCode,
  clearPrintProgress
} from "../../../utils/hardware";
import mixins from "./mixin";
import { speak } from "../../../utils/hardware";
import { merchantuthPayFormWayInfo } from "@/api/pay";
import { payDeductionOrder } from "@/api/order";
import {
  tradePaymentInfoMicropayV2,
  tradePaymentInfoStatus
} from "@/api/order";
export default {
  name: "pay-way",

  mixins: [mixins],

  data() {
    return {
      memberPay: {}, // 会员支付方式名称
      payCode: "", // 凭证码
      loading: false,
      format: "", // 业态
      orderId: "", // 订单id
      payOnline: false, // 是否是线上支付
      loopTimer: null, // 轮询支付定时器
      payType: "", // 支付状态
      hidden: null,
      timeNum: 60,
      timer: null,
      timer2: null,
      process: 0, // 当前进度
      total: 0, // 出票总数
      printProcessDialog: false // 打印弹窗
    };
  },
  created() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timeNum = 60;
    }
    if (this.timer2) {
      clearTimeout(this.timer2);
    }
    this.format = this.$route.query.format || "";
    this.orderId = this.$route.query.orderId || "";
    if (this.format && this.$route.query.price === "0") {
      // 是0元订单就直接支付成功
      this.success();
      return;
    }
    this.getPayMode();
  },

  // 清除监听和定时器
  destroyed() {
    clearTimeout(this.loopTimer);
    clearPrintProgress();
    clearScanCode();
  },

  methods: {
    // 获取支付方式
    getPayMode() {
      merchantuthPayFormWayInfo()
        .then(res => {
          // payFormCategory: 支付形式  1线上支付、2线下支付， 3挂账， 4抵扣
          res.forEach(item => {
            if (item.payFormCategory === 1) {
              this.payOnline = true;
            }
            if (item.payFormCategory === 4) {
              const temp = item.authPayWayVOS.find(
                temp => temp.payWayCode === "MemberBalance"
              );
              if (temp) {
                this.memberPay = temp;
              }
            }
          });
          if (!this.payOnline) {
            let that = this;
            this.$info({
              title: "提示",
              content: "线上支付配置未开启，请先去后台启用线上支付功能!",
              onOk() {
                that.$router.back();
              }
            });
          } else {
            // 测试 会员支付
            // this.payCode = "153531725617D5988";
            // this.doPay();
            // 测试
            let isTrue = scanCode(this.payScan);
            if (isTrue) {
              speak("请将微信或支付宝支付码对准右下角扫描器");
            } else this.$message.warning("扫码支付，硬件启用失败");
          }
        })
        .catch(() => {});
    },
    payScan(res) {
      this.payCode = res;
      this.doPay();
    },
    doPay() {
      if (!this.payCode) {
        this.$message.warning("请先输入付款码");
        return;
      }
      if (this.loading) {
        this.$message.warning("请勿重复扫码");
        return;
      }
      this.loading = true;
      this.$store.commit("common/updateLoading", true);
      this.payType = "";
      // 检查是否会员码
      if (/\d{12}D\d{4}/.test(this.payCode) && this.format) {
        // this.format 兑换币充值禁用会员余额支付
        if (!this.memberPay.payWayCode) {
          // 如果未配置会员余额支付时提示
          this.$info({
            title: "提示",
            content:
              "会员余额支付配置未开启，请先去后台配置或改用微信或支付宝支付!",
            onOk() {
              this.resetData();
            }
          });
          return;
        }
        const obj = {
          orderId: this.orderId,
          payWayCode: "MemberBalance",
          payWayName: this.memberPay.payWayName,
          memberCode: this.payCode
        };
        payDeductionOrder(obj)
          .then(() => {
            this.success();
          })
          .catch(() => {
            this.resetData();
          });
      } else {
        const data = {
          orderNo: this.orderId,
          authCode: this.payCode
        };
        tradePaymentInfoMicropayV2(data, { loading: false })
          .then(res => {
            // 等待支付
            if (res === "PAYING" || res === "NOT_PAY") {
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              speak("支付失败，请重新支付");
              this.$message.error("支付失败，请重新支付");
              this.payType = "-1";
              this.resetData();
            }
            // 除了以上状态，其他都为成功
            else {
              this.success();
            }
          })
          .catch(() => {
            this.resetData();
          });
      }
    },
    // 轮询支付状态
    getStatusLoop() {
      if (this.loopTimer) {
        clearTimeout(this.loopTimer);
      }
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus({ orderNo: this.orderId, loading: false })
          .then(res => {
            if (res === "PAYING" || res === "NOT_PAY") {
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              speak("支付失败，请重新支付");
              this.$message.error("支付失败，请重新支付");
              this.payType = "-1";
              this.resetData();
            }
            // 除了以上状态，其他都为成功
            else {
              this.success();
            }
          })
          .catch(() => {
            this.resetData();
          });
      }, 2000);
    },
    // 支付成功
    success() {
      this.$message.success("支付成功");
      this.$store.commit("common/updateLoading", false);
      this.payType = "1";
      this.payCountdown();
      // 调打印和语音播报
      // 需要打印，则通过地址栏传业态过来识别
      this.payOk(this.format, this.orderId);
    },
    // 支付成功倒计时
    payCountdown() {
      this.timer = setInterval(() => {
        // console.log("支付倒计时+++++++++");
        if (this.timeNum <= 0) {
          clearInterval(this.timer);
          this.printProcessDialog = false;
          clearPrintProgress();
          this.$router.back();
          return;
        }
        this.timeNum = this.timeNum - 1;
      }, 1000);
    },
    continueSure() {
      clearInterval(this.timer);
      this.$router.back();
    },
    printProgress(params) {
      // console.log(params, "---*----打印进度回传数据-");
      if (params) {
        const arr = params.split(",");
        this.total = arr[0];
        this.process = arr[1];
        if (this.process >= this.total) {
          this.timer2 = setTimeout(() => {
            speak("出票成功,请取票");
            this.printProcessDialog = false;
            clearTimeout(this.timer2);
          }, 1000);
        }
      }
    },
    resetData() {
      this.payCode = "";
      this.loading = false;
      this.$store.commit("common/updateLoading", false);
    }
  }
};
</script>

<style scoped lang="less">
.qr-wrap {
  img {
    width: 643px;
  }
}
.font-32 {
  font-size: 32px;
  line-height: 38px;
}
@import "../../../assets/less/custom.less";
.pay-success-wrap {
  max-width: 600px;
  margin: 0 auto 0;
  text-align: center;
  .check-icon {
    width: 110px;
    height: 110px;
    text-align: center;
    line-height: 110px;
    background: #31ba61;
    color: #fff;
    border-radius: 50%;
    margin: 0 auto 30px;
    font-size: 58px;
  }
  .btn-lg {
    width: 253px;
    height: 60px;
    background: @primary;
    border-radius: 30px;
    margin-top: 50px;
  }
}
</style>
