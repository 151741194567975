<!--
  @name: Footer
  @author: zhengjiana
-->
<template>
  <div class="footer">
    <ul class="left">
      <li class="des">产品数量：{{ cart.productNum || 0 }}张</li>
      <li class="des">
        总金额：¥{{
          cart.settlementPrice ? (cart.settlementPrice / 100).toFixed(2) : 0
        }}
      </li>
      <li class="des">
        结算金额：<span class="danger"
          >¥{{
            cart.settlementPrice ? (cart.settlementPrice / 100).toFixed(2) : 0
          }}</span
        >
      </li>
    </ul>
    <div class="right">
      <p class="warn">
        扫描身份证快速下单
      </p>
      <div class="btns">
        <!-- <qmp-button @click="toLogin">登录会员</qmp-button> -->
        <qmp-button type="primary" @click="toOrder">提交订单</qmp-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createQuickOrder } from "@/api/order";
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  props: {
    format: {
      type: [String, Number],
      default: 6
    },
    cart: {
      type: Object,
      default() {
        return {
          id: "",
          productNum: 0,
          settlementPrice: 0
        };
      }
    }
  },
  data() {
    return {
      subOrder: ""
    };
  },
  computed: {
    ...mapGetters("order", ["isSubOrder,canSubOrder"])
  },
  methods: {
    // 登录会员
    toLogin() {
      this.$router.push(``);
    },
    toOrder() {
      setTimeout(() => {
        this.order();
      }, 500);
    },
    async order() {
      if (!this.cart.id) {
        this.$message.warning("请先选择产品！");
        return;
      }
      this.$store.commit("order/updateIsSubOrder", true);
      clearInterval(this.subOrder);
      // 游客信息请求还没结束时
      if (this.isSubOrder && !this.canSubOrder) {
        this.$message.warning("下单中...");
        this.subOrder = setInterval(() => {
          this.order();
        }, 500);
        return;
      }
      this.$store.commit("order/updateIsSubOrder", false);
      // let validateConfirm = this.$emit("subValidateConfirm");
      // if (validateConfirm) {
      //   this.$message.warning(validateConfirm);
      //   return;
      // }
      const res = await createQuickOrder({
        shoppingCartId: this.cart.id
      });
      if (res) {
        // 需要打印，则传业态format
        // format 业态 6票务，13 组合。 orderId 订单id, price 订单价格
        this.$router.push({
          path: "/payment/pay",
          query: {
            orderId: res.orderId,
            format: this.format,
            price: res.settlementPrice
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  padding: 26px 34px;
  height: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 110px;
  font-size: 22px;
  color: #111;

  .left {
    flex: 1;
  }

  .des {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .danger {
    font-size: 28px;
    color: #ff1b1b;
  }

  .right {
    text-align: right;
  }

  .warn {
    color: #ff5c2e;
    position: relative;
    margin-bottom: 26px;

    &::before {
      position: absolute;
      right: 206px;
      top: 0;
      font-family: "iconfont";
      content: "\ea23";
    }
  }
}

.button + .button {
  margin-left: 20px;
}
</style>
