import { render, staticRenderFns } from "./BuyTime.vue?vue&type=template&id=646a448b&scoped=true&"
import script from "./BuyTime.vue?vue&type=script&lang=js&"
export * from "./BuyTime.vue?vue&type=script&lang=js&"
import style0 from "./BuyTime.vue?vue&type=style&index=0&id=646a448b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646a448b",
  null
  
)

export default component.exports