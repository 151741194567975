/**
 * 新下单打印和支付成功方法
 * */
import { speak, printTicket, printProgress } from "../../../utils/hardware";
import { orderQuickTicketPrint } from "@/api/search";
import { updatePaper } from "../../../utils/global";
export default {
  methods: {
    // type 业态，orderId 订单id
    payOk(type, orderId) {
      // 语音播报
      speak("支付成功");
      // 有传业态，走打印
      if (type) this.printProof(type, orderId);
    },
    // 打印凭证
    printProof(type, orderId) {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }
      // 票务、组合、游乐调用相同的打印硬件方法
      // formats 业态：6票务 7游乐 8剧院 9酒店 10餐饮 11零售 12租赁 13组合
      orderQuickTicketPrint({
        orderId: orderId,
        templateId: ticketTempId,
        formats: type
      })
        .then(res => {
          if (res && res.length) {
            try {
              const bool = printTicket(JSON.stringify(res));
              if (!bool) {
                speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              speak("打印门票中，请勿离开");
              // 更新纸张数
              updatePaper(1, res.length, this);
              printProgress(this.printProgress);
              this.printProcessDialog = true;
            } catch (error) {
              speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          } else {
            speak("购买的产品里有腕带票，请去窗口取票");
          }
        })
        .catch(() => {
          console.log("打印门票接口报错");
        });
    }
  }
};
