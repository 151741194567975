/**
 * 支付中心 API
 **/
import http from "../utils/fetch";
const prefix = "/api/pay";

// 支付时查询支付方式列表【通用接口】
export const merchantuthPayFormWayInfo = data => {
  return http.get(`${prefix}/merchant/auth/pay/form/way/info`, data);
};
