<template>
  <!-- 票务 -->
  <div>
    <!-- 产品列表 -->
    <ProductList ref="ProductList" @update-cart="updateCart" :format="id" />
  </div>
</template>

<script>
import ProductList from "../components/ProductList.vue";
import { combination, combinationMore } from "@/api/cart";
export default {
  name: "CombinationIndex",
  components: { ProductList },
  props: {
    id: {
      type: [String, Number],
      default: 6
    }
  },
  data() {
    return {
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        categoryId: "", // 分类id
        appointmentRules: "", // 分时预约规则id
        timeId: "" // 时段id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      }
    };
  },
  methods: {
    // 加载入口
    changeFilter(params) {
      this.params = params;
      this.$refs.ProductList.changeFilter(params);
    },
    // 添加/更新到购物车 - 调接口
    async updateCart(obj) {
      // 添加购物车
      let api = combination;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = combinationMore;
      }
      // 添加会员信息
      else {
        // 如果已经有会员信息
        if (this.memberInfo) {
          obj.purchaserMemberId = this.memberInfo.id;
        }
        // 如果本地存有默认打印方式
        if (this.printType) {
          obj.cartPrintType = this.printType;
        }
      }

      let ok = true;
      await api(obj)
        .then(res => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .catch(() => {
          ok = false;
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
      return ok;
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      };
      this.broadcast();
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.ProductList.updateNum(this.cart);
    }
  }
};
</script>

<style scoped></style>
