/**
 * MS-购物车接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/cart"; // api前缀

// 【cart001】添加产品到购物车-刘资涌
export function addShoppingCart(data) {
  return http.add(`${prefix}/shopping/cart`, data);
}

// 【cart002】更新购物车-刘资涌
export function updateShoppingCart(data) {
  return http.put(`${prefix}/shopping/cart`, data);
}

// 【cart001】展示单个购物车商品 / 取单 -刘资涌
export function shoppingCartList(data) {
  return http.get(`${prefix}/shopping/cart`, data);
}

//【cart007】取单中的删除/整单取消/更改游玩日期 -刘资涌
export const deleteOrCancel = data => {
  return http.delete(`${prefix}/delete/or/cancel`, data);
};

//点击加购推荐产品之后展示的列表 -刘资涌
export function addPurchaseProduct(data) {
  return http.get(`${prefix}/add/purchase/product`, data);
}

//登记信息并结账按钮 -刘资涌
export function registerSettleAccounts(data) {
  return http.get(`${prefix}/register/settle/accounts`, data);
}

//校验并保存前端传来的出游人信息 -刘资涌
export function visitorFieldInfo(data) {
  return http.add(`${prefix}/visitor/field/info`, data);
}

//保存前端传来的优惠信息 -刘资涌
export function discountInfoCalculate(data) {
  return http.post(`${prefix}/discount/info/calculate`, data);
}
// 【cart001】门票/会员充值，查询票号、查询会员手机号，生成购物车 -刘资涌
export const recharge = (data, config) => {
  return http.get(`${prefix}/recharge`, data, config);
};
// 【cart002】一次性传多个产品进购物车（门票充值和单买项目套餐） -lzy
export const manyProduct = data => {
  return http.post(`${prefix}/many/product`, data);
};

// 添加多个产品，创建购物车
export const createCart = data => {
  return http.post(`${prefix}/create/cart`, data);
};

// 【cart001】取票和补办 -刘资涌
export function getTicketOrReissue(data) {
  return http.post(`${prefix}/get/ticket/or/reissue`, data);
}

// 新版
//添加票务产品到购物车
export const cartTicket = data => {
  return http.post(`${prefix}/ticket`, data);
};
//更新产品到购物车
export const ticketMore = data => {
  return http.post(`${prefix}/ticket/more`, data);
};
//惠民政策，通过目标产品找到多个特惠产品让前端选择
export const selectDiscountSku = data => {
  return http.get(`${prefix}/select/discount/sku`, data);
};
//票务更新出游人信息 http://192.168.1.50:40001/project/41/interface/api/3900
export const ticketTraveller = data => {
  return http.post(`${prefix}/ticket/traveller`, data, { loading: true });
};
// 组合产品 - 修改单个出游人的单个信息 http://192.168.1.50:40001/project/41/interface/api/3992
export const combinationTraveller = data => {
  return http.post(`${prefix}/combination/traveller`, data, { loading: true });
};
// 新的会员充值 http://192.168.1.50:40001/project/41/interface/api/3951
export const memberRecharge = data => {
  return http.post(`${prefix}/member/recharge`, data);
};
//查询加购产品列表 http://192.168.1.50:40001/project/41/interface/api/3916
export const ticketAddBuy = data => {
  return http.get(`${prefix}/ticket/add/buy`, data);
};
//修改加购数量-票务 http://192.168.1.50:40001/project/41/interface/api/3901
export const postTicketAddBuy = data => {
  return http.post(`${prefix}/ticket/add/buy`, data);
};
// 票务 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4147
export const ticketCommonlyVisitorBatch = data => {
  return http.post(`${prefix}/ticket/commonly/visitor/batch`, data);
};
// 组合产品 - 修改加购产品的数量 http://192.168.1.50:40001/project/41/interface/api/3993
export const combinationAddBuy = data => {
  return http.post(`${prefix}/combination/add/buy`, data);
};
//更新快票开关 http://192.168.1.50:40001/project/41/interface/api/3908
export const ticketFast = data => {
  return http.post(`${prefix}/ticket/fast`, data);
};
// 组合产品 - 组合产品 - 打开或关闭快票 http://192.168.1.50:40001/project/41/interface/api/3991
export const combinationFast = data => {
  return http.post(`${prefix}/combination/fast`, data);
};
//票务和组合 - 批量给产品添加加购 http://192.168.1.50:40001/project/41/interface/api/4237
export const commonAddBuyBatch = data => {
  return http.post(`${prefix}/common/add/buy/batch`, data);
};
//修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3898
export const ticketDate = data => {
  return http.post(`${prefix}/ticket/date`, data);
};
//更新购物车时段 http://192.168.1.50:40001/project/41/interface/api/3899
export const ticketAppointmentDate = data => {
  return http.post(`${prefix}/ticket/appointment/date`, data);
};
// 组合产品 - 修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3989
export const combinationDate = data => {
  return http.post(`${prefix}/combination/date`, data);
};
// 组合产品 - 修改选择时段 http://192.168.1.50:40001/project/41/interface/api/3990
export const combinationAppointmentDate = data => {
  return http.post(`${prefix}/combination/appointment/date`, data);
};
// 组合产品 - 添加购物车，参数和票务一样 http://192.168.1.50:40001/project/41/interface/api/3986
export const combination = data => {
  return http.post(`${prefix}/combination`, data);
};
// 组合产品 - 增加新产品和改数量 http://192.168.1.50:40001/project/41/interface/api/3987
export const combinationMore = data => {
  return http.post(`${prefix}/combination/more`, data);
};
// 一产品一游客组合 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4150
export const combinationCommonlyVisitorBatch = data => {
  return http.post(`${prefix}/combination/commonly/visitor/batch`, data);
};
