<!--
  @name: 身份证输入框
  @author: fengyanlong
  @attr: {
    v-model:{
      type: String   //身份证ID
    }
  }
  @event:{
    change:fn()   //组件内身份证id改变时触发
    readCard:reutnr{
      sex:男、女
      photo:base64图片
      name:姓名
      birth: 生日  yyyy-mm-dd
    } //把读到的身份证信息返回到组件外
  }
-->
<template>
  <a-input
    :size="size"
    ref="IdInput"
    :value="value"
    :placeholder="placeholder"
    @focus="listen"
    @blur="stop"
    :maxLength="18"
    @change="handleChange"
    :class="{ myInput: size !== 'large', 'font-xxxxl': size === 'default' }"
  >
    <span
      slot="addonAfter"
      class="iconfont icon-credentials_icon myicon primary"
      :class="{ 'font-xxxxl': size === 'default' }"
    ></span>
  </a-input>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "IdCardInput",
  model: {
    prop: "value"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "请刷身份证/输入身份证号"
    },
    size: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      cardNo: "",
      cardInfo: null,
      ready: false
    };
  },
  computed: {
    ...mapState("order", ["page"]),
    isShowWarningText() {
      return this.value !== "";
    }
  },

  watch: {
    page() {
      this.$refs.IdInput.focus();
    }
  },
  methods: {
    readIdCard(res) {
      this.cardInfo = JSON.parse(res);
      //this.$message.success(res);
      if (this.ready) {
        //this.value = this.cardInfo.id;
        this.$emit("input", this.cardInfo.id);
        this.$emit("change");
        this.$emit("readCard", this.cardInfo);
      }
    },
    listen() {
      // this.$message.success("listen");
      window.readIdCard = this.readIdCard;
      this.ready = true;
      this.$emit("idcardFocus");
    },
    stop() {
      //this.$message.success("stop");
      this.ready = false;
      this.$emit("idcardBlur");
    },
    handleChange(e) {
      this.$emit("input", e.target.value);
      this.$emit("change");
      this.$emit("update:value", e.target.value);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
.iconfont.myicon {
  // font-size: 30px;
}
.myInput/deep/.ant-input {
  min-width: 400px;
  height: 60px;
  // font-size: 32px;
  // border-radius: @1x;
}
// /deep/.ant-input {
//   min-width: 400px;
//   height: 60px;
//   font-size: 32px;
//   border-radius: @1x;
// }
</style>
