<!--  -->
<template>
  <div>
    <a-modal
      centered
      :visible="visible"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
      width="70%"
    >
      <h3 class="flex-center bold bd-b pb-2x">预约时间</h3>
      <!-- content -->
      <div class="flex-start">
        <div style="width:50%" class="bd-r">
          <a-calendar
            :fullscreen="false"
            v-model="defaultValue"
            :disabledDate="disabledDate"
            @select="selectDate"
          />
        </div>
        <div style="width:50%">
          <p class="bold ml-2x">选择时段：</p>
          <div class="flex-start flex-wrap">
            <div
              v-for="item in times"
              :key="item.id"
              :class="{ primary: timeObj.timeId === item.id }"
              :style="{
                borderColor: timeObj.timeId === item.id ? '#00C250' : ''
              }"
              class="bd ph-2x pv-1x ml-2x mt-1x time-box"
              @click="clickTime(item)"
            >
              <span>{{ item.beginTimeFormat }} - {{ item.endTimeFormat }}</span>
              <!-- <p>库存{{ item.remainingStock }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex-center pt-3x bd-t">
          <a-button
            @click="handleCancel"
            size="large"
            class="ml-2x mybtn"
            shape="round"
          >
            取消
          </a-button>
          <a-button
            class="ml-2x mybtn"
            size="large"
            type="primary"
            shape="round"
            @click="handleOk"
          >
            确定
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import { appointmentStockDate } from "@/api/product";
export default {
  name: "SelectDateAndTime",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "", // 分类id
          appointmentSelection: "", //分时预约选择 1.产品  2.子景区 3.景区
          visible: false //预约日期时段弹框
        };
      }
    }
  },
  data() {
    return {
      timeShareAppointmentRuleId: "",
      times: [],
      defaultValue: "",
      timeObj: { playDate: "", timeId: "" },
      minDate: "",
      maxDate: "",
      orderRules: {} //下单规则
    };
  },
  methods: {
    disabledDate(current) {
      // return current < moment().startOf("day");
      return (
        current <
          moment(this.minDate)
            .startOf("day")
            .valueOf() ||
        current >=
          moment(this.maxDate)
            .endOf("day")
            .valueOf()
      );
    },
    /**
     * 预约日期限制处理
     * orderRules 下单规则&产品有效期-交集
     * fixValidStartTime fixValidEndTime 产品有效期
     */
    handleDate() {
      let orderRules = sessionStorage.getItem("orderRules");
      this.orderRules = orderRules ? JSON.parse(orderRules) : ""; //下单规则
      let obj = this.orderRules;
      // 没有下单规则
      if (!obj) {
        // 有产品有效期
        if (this.item && this.item.fixValidStartTime) {
          this.minDate = moment(this.item.fixValidStartTime);
          this.date = this.item.fixValidStartTime
            ? moment(this.item.fixValidStartTime)
            : "";
          this.maxDate = moment(this.item.fixValidEndTime);
        } else {
          this.minDate = moment(); //今天往后不限
        }
      } else {
        //有下单规则 1不限 2t+后n 3 T+后不限
        if (obj.orderDateRange == 1) {
          //还有产品有效期
          if (this.item && this.item.fixValidStartTime) {
            this.minDate = moment(this.item.fixValidStartTime);
            if (this.item.fixValidStartTime < moment().valueOf()) {
              this.minDate = moment();
            }
            this.date = this.minDate;
            this.maxDate = moment(this.item.fixValidEndTime);
          } else {
            this.minDate = moment(); //今天往后不限
            this.date = this.minDate;
          }
        } else if (obj.orderDateRange == 2) {
          // 2 t+后n
          //还有产品有效期
          if (this.item && this.item.fixValidStartTime) {
            // minDate 哪个大取哪个
            let minDate = this.dateCompare(
              moment().add(+Number(obj.tafterDay), "day"),
              moment(this.item.fixValidStartTime)
            )
              ? moment(this.item.fixValidStartTime)
              : moment().add(+Number(obj.tafterDay), "day");
            this.minDate = minDate;
            this.date = minDate;
            // maxDate 哪个小取哪个
            let maxDate = this.dateCompare(
              moment().add(+Number(obj.ttoDay + obj.tafterDay), "day"),
              moment(this.item.fixValidEndTime)
            )
              ? moment().add(+Number(obj.ttoDay + obj.tafterDay), "day")
              : moment(this.item.fixValidEndTime);
            this.maxDate = maxDate;
          } else {
            this.minDate = moment().add(+Number(obj.tafterDay), "day");
            this.maxDate = moment().add(
              +Number(obj.ttoDay + obj.tafterDay),
              "day"
            );
            this.date = this.minDate;
          }
        } else if (obj.orderDateRange == 3) {
          // t+n后不限
          //还有产品有效期
          if (this.item && this.item.fixValidStartTime) {
            // minDate 哪个大取哪个
            let minDate = this.dateCompare(
              moment().add(+Number(obj.tafterDay), "day"),
              moment(this.item.fixValidStartTime)
            )
              ? moment(this.item.fixValidStartTime)
              : moment().add(+Number(obj.tafterDay), "day");
            this.minDate = minDate;
            this.date = minDate;
            // maxDate 哪个小取哪个
            let maxDate = this.item.fixValidEndTime;
            this.maxDate = maxDate;
          } else {
            this.minDate = moment().add(+Number(obj.tafterDay), "day");
            this.maxDate = "";
            this.date = this.minDate;
          }
        }
      }
      this.defaultValue = moment(this.date);
      this.timeObj.playDate = this.defaultValue.valueOf();
    },
    /**
     * 比较时间大小 格式
     * return startDate<=endDate
     */
    dateCompare(startDate, endDate) {
      if (startDate && endDate && startDate.valueOf() <= endDate.valueOf()) {
        return true;
      } else {
        return false;
      }
    },
    open(timeShareAppointmentRuleId) {
      this.defaultValue = this.params.playDate
        ? moment(this.params.playDate)
        : moment();
      this.handleDate();
      this.timeObj.playDate = this.defaultValue.valueOf();
      this.timeShareAppointmentRuleId = timeShareAppointmentRuleId;
      this.getAppointmentStockDate(this.defaultValue.valueOf());
    },
    handleOk() {
      this.$emit("timeOk", this.timeObj);
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    //点击日期
    selectDate(value) {
      this.timeObj.playDate = value.valueOf();
      this.getAppointmentStockDate(value.valueOf());
    },
    clickTime(item) {
      this.timeObj.beginTime = item.beginTime;
      this.timeObj.endTime = item.endTime;
      this.timeObj.timeId = item.id;
    },
    // 获取时段列表
    async getAppointmentStockDate(date) {
      const obj = {
        date: date
      };
      // 子景区维度的分时预约需要根据子景区id获取时段
      if (this.params.appointmentSelection === 2) {
        obj.subScenicId = this.params.subScenicId;
      }
      // 景区维度则需要根据后台设置选中的规则id获取
      else {
        obj.ruleId = this.timeShareAppointmentRuleId; // 规则id
      }
      const data = await appointmentStockDate(obj);
      this.times = data
        .filter(val => val.remainingStock)
        .map(item => ({
          ...item,
          beginTimeFormat: moment(item.beginTime).format("hh:mm"),
          endTimeFormat: moment(item.endTime).format("hh:mm")
        }));

      // 子景区分时预约规则
      if (this.params.appointmentSelection === 2 && data.length)
        this.params.appointmentRules = data[0].ruleId;

      this.timeObj.timeId = this.times?.[0]?.id || 0;
      this.timeObj.beginTime = this.times?.[0]?.beginTime;
      this.timeObj.endTime = this.times?.[0]?.endTime;
      return data;
    }
  }
};
</script>
<style lang="less" scoped>
.time-box {
  border-radius: 6px;
}
.mybtn {
  padding: 0 70px;
}
/deep/.ant-fullcalendar-header {
  display: flex;
}
</style>
