/**
 *文件上传
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/upload`; //服务前缀

/**
 * 文件上传
 */
//base64接口文件上传 -刘资涌
export const fileUploadBase64 = data => {
  return http.add(`${prefix}/file/upload/base64`, data);
};
//excel文件上传 -刘资涌
export const fileUploadExcel = data => {
  return http.post(`${prefix}/file/upload/excel`, data);
};
//文件上传接口 -刘资涌
export const fileUpload = (data, config) => {
  return http.post(`${prefix}/file/upload`, data, config);
};
