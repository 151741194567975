<template>
  <qmp-layout title="可取门票">
    <!--列表-->
    <ul>
      <li
        class="list-item flex-between"
        v-for="item in list"
        :key="item.id"
        @click="checkItem(item)"
      >
        <div class="iconfont icon-duia" v-show="item.checked"></div>
        <div class="iconfont icon-duia1" v-show="!item.checked"></div>
        <img class="img flex-shrink" :src="item.skuImages" alt="" />
        <div class="flex-grow right flex-between flex-col">
          <div class="title flex-start">
            <p class="flex-grow mr-2x">{{ item.productName }}</p>
            <p class="align-start">x{{ item.buyNum }}</p>
          </div>
          <p>
            使用有效期：{{ item.beginValidTime | date }} ~
            {{ item.endValidTime | date }}
          </p>
          <p>预约日期：{{ item.useDate | date }}</p>
          <p>
            预约时段：{{ item.appointmentPeriodStartTime }} ~
            {{ item.appointmentPeriodEndTime }}
          </p>
        </div>
      </li>
    </ul>

    <footer slot="footer" class="flex-between p-4x bg-white">
      <div class="pointer flex-start" @click="checkAll">
        <i class="iconfont icon-duia" v-show="checkedAll"></i>
        <i class="iconfont icon-duia1" v-show="!checkedAll"></i>
        <span class="ml-2x">全选</span>
      </div>
      <div>
        <span class="font-xxxxl text-v">已选 {{ total }} 张</span>
        <qmp-button class="ml-3x text-v" type="primary" @click="takeClick"
          >取票</qmp-button
        >
      </div>
    </footer>

    <!-- 打印进度弹窗 -->
    <a-modal
      :centered="true"
      :footer="false"
      v-model="printProcessDialog"
      :mask-closable="false"
    >
      <img src="../../../assets/images/print.png" alt="print-img" />
      <p class="font-xl text-c bold">{{ process }}/{{ all }}</p>
      <p class="font-xl text-c bold">请勿离开！</p>
      <p class="font-xl text-c bold">正在出票中...</p>
    </a-modal>
  </qmp-layout>
</template>

<script>
import { orderSelfFetchTicket } from "../../../api/search";

import mixin from "./mixin";
import { clearPrintProgress, speak } from "../../../utils/hardware";

export default {
  name: "TakeList",

  mixins: [mixin],

  data() {
    return {
      list: [],
      checkedAll: false, // 全选
      process: 0, // 当前进度
      all: 0, // 出票总数
      printProcessDialog: false // 打印弹窗
    };
  },

  computed: {
    total() {
      return this.list.reduce((prev, next) => {
        return prev + Number(next.checked);
      }, 0);
    }
  },

  created() {
    this.loadData();
  },

  beforeDestroy() {
    clearPrintProgress();
  },

  methods: {
    // 加载数据
    loadData() {
      orderSelfFetchTicket({ keyword: this.$route.query.keyword })
        .then(res => {
          res.forEach(item => {
            item.checked = false;
          });
          this.list = res;
        })
        .catch(() => {});
    },
    // 全选
    checkAll() {
      let every = this.list.every(item => item.checked);
      this.list.forEach(item => {
        item.checked = !every;
      });
      this.checkedAll = !every;
    },

    // 单选
    checkItem(item) {
      item.checked = !item.checked;
      this.checkedAll = !this.list.some(temp => !temp.checked);
    },
    takeClick() {
      const ids = this.list
        .filter(item => item.checked)
        .map(item => {
          return item.id;
        });
      if (!ids.length) {
        this.$message.warning("请选择门票");
        return;
      }
      this.takeNow(ids);
    },
    // 打印进度
    printProgress(params) {
      // console.log(params, "---*----打印进度回传数据-");
      if (params) {
        const arr = params.split(",");
        this.all = arr[0];
        this.process = arr[1];
        if (this.process >= this.all) {
          this.timer2 = setTimeout(() => {
            speak("出票成功,请取票");
            this.printProcessDialog = false;
            this.$router.back();
            clearTimeout(this.timer2);
          }, 1000);
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.iconfont {
  font-size: 42px;
  color: #c8c8c8;
  flex-shrink: 0;
  &.icon-duia {
    color: #19c748;
  }
}
.list-item {
  padding: 32px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 22px;
  background: #fff;
  .img {
    margin-left: 32px;
    width: 200px;
    height: 200px;
    border-radius: 8px;
  }

  .right {
    height: 200px;
    margin-left: 33px;
    .title {
      font-size: 28px;
    }
    .title,
    > p {
      width: 100%;
    }
    > p {
      color: #767676;
    }
  }
}
</style>
