<template>
  <!-- 产品列表 -->
  <section
    id="ticketBox"
    class="section ticket-box pb-2x"
    ref="ticketBox"
    :class="{ isPadding: isonFocus }"
  >
    <ul class="ticket-list flex-col flex-between flex-wrap" v-if="list.length">
      <li
        v-for="(item, index) in list"
        :key="item.id"
        class="ticket-list-item p-x"
        :class="{ 'mt-2x': index > 0 }"
      >
        <div class="p-1x ph-2x flex-start" :class="{ 'bd-b': item.buyNum }">
          <img class="img" :src="item.skuImages" alt="" />
          <div class="right-content">
            <p class="bold text-2 font-xl res mb-2x">
              {{ item.name }}
            </p>
            <div class="mb-2x flex-between">
              <span class="blue ph-2x pv-x">{{ item.merchantName }}</span>
              <div class="gray">
                <!-- <span class="iconfont mr-x">&#xea23;</span>购票须知 -->
              </div>
            </div>
            <div class="flex-between mb-1x font-xl">
              <div class="flex-start align-center">
                <b class="price">¥ {{ item.sellingPrice }}</b>
                <del class="disabled ml-1x">{{ item.marketPrice | money }}</del>
              </div>
            </div>
            <div class="flex-between">
              <div class="gray" @click="toNotice(item)">
                <span class="iconfont mr-x">&#xea23;</span>购票须知
              </div>
              <!-- <div class="product-setting">
                预约时段：-
                <span class="iconfont">&#xe669;</span>
              </div> -->
              <!-- 加减数量 -->
              <div class="flex-start gray mr-1x">
                <!-- 减 -->
                <div v-if="item.buyNum" class="flex-start">
                  <span
                    class="iconfont minus"
                    @click="updateCart(item, item.buyNum - 1, index)"
                    >&#xe748;</span
                  >
                  <div class="minus-num">{{ item.buyNum }}</div>
                </div>
                <!-- 加 -->
                <span
                  @click="updateCart(item, item.buyNum + 1, index)"
                  class="iconfont primary minus"
                  >&#xe749;</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 游客信息  -->
        <ProductPlayer
          v-if="item.buyNum > 0"
          :ref="'ProductPlayer' + index"
          :params="params"
          @update-player="updatePlayer"
          @update-add-buy="changeAddBuy"
          @update-add-buy-batch="updateAddBuyBatch"
          @update-fast="updateFast"
          @update-date="updateDate"
          @quickAddCart="quickAddCart"
          @del-item="delItem"
          @onFocus="onFocus"
        ></ProductPlayer>
      </li>
    </ul>
    <a-empty v-else class="font-l disabled" style="margin-top: 300px;" />

    <!-- 购票须知 -->
    <a-modal
      centered
      :visible="showNotice"
      :footer="null"
      @ok="showNotice = false"
      @cancel="showNotice = false"
      width="70%"
    >
      <h3 class="flex-center bold bd-b pb-2x">购票须知</h3>
      <!-- content -->
      <div class="pv-2x res buy-notice">
        <div v-html="notice"></div>
      </div>
      <div>
        <div class="flex-center pt-3x bd-t">
          <a-button
            class="ml-2x res"
            size="large"
            type="primary"
            shape="round"
            @click="showNotice = false"
          >
            确定
          </a-button>
        </div>
      </div>
    </a-modal>
  </section>
</template>

<script>
import {
  productSelfTicketproduct,
  productSelfCombinationproduct
} from "@/api/search";
import ProductPlayer from "./ProductPlayer.vue";
import {
  combinationTraveller,
  ticketTraveller,
  postTicketAddBuy,
  combinationAddBuy,
  ticketFast,
  combinationFast,
  commonAddBuyBatch,
  ticketDate,
  ticketAppointmentDate,
  combinationDate,
  combinationAppointmentDate,
  ticketCommonlyVisitorBatch,
  combinationCommonlyVisitorBatch
} from "@/api/cart";
import { userinfoSettingInfoList } from "@/api/product";
export default {
  name: "ProductList",
  components: { ProductPlayer },
  inject: ["changeCart"],
  props: {
    format: {
      type: [String, Number],
      default: 6
    }
  },
  data() {
    return {
      api: { 6: productSelfTicketproduct, 13: productSelfCombinationproduct }, //业态-产品列表接口
      showPicker: false,
      page: 1,
      size: 999,
      list: [], // 产品列表
      // 购物车数据
      cartId: 0, // 购物车id
      isShowProductSettingMode: false, // 产品有效期设置
      setProductId: "", // 设置产品有效期产品id
      showProductValiditySetting: [], // 单个产品的产品有效期设置
      isShowTicketsOperation: false, // 取票操作
      productTakeTicketDefault: "", // 默认取票操作
      tempValue: "",
      showSureIndex: "",
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        name: "", // 产品名称
        categoryId: "", // 分类id
        appointmentRules: "", // 分时预约规则id
        timeId: "" // 时段id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false,

      currentIndex: 0,
      showNotice: false, //购票须知
      notice: "", //购票须知
      isonFocus: false
    };
  },

  mounted() {
    window.readIdCard = this.quickAddCart; //把身份证回调切回快速匹配
  },
  methods: {
    onFocus() {
      this.isonFocus = !this.isonFocus;
      // 先手动滚动 自动滚下次先
      // this.$nextTick(() => {
      //   let el = document.getElementById("ticketBox");
      //   el.scrollIntoView(false); //底部与外部底部齐平
      // });
    },
    // 快速匹配下单
    quickAddCart(res) {
      if (!res) return;
      res = JSON.parse(res);
      if (!res.id) {
        this.$message.warning("证件匹配错误");
        return;
      }
      // 先动态获取 游客信息的id
      userinfoSettingInfoList().then(temp => {
        let field = temp.find(ite => ite.fieldType === 6);
        let obj = {
          playDate: this.params.playDate,
          subScenicId: this.params.subScenicId,
          travelers: [
            {
              visitorFieldList: [
                { id: field.id, fieldType: 6, fieldValue: res.id }
              ]
            }
          ]
        };
        // 加入购物车
        let api = ticketCommonlyVisitorBatch;
        if (this.format == 13) {
          api = combinationCommonlyVisitorBatch;
        }
        api(obj).then(res => {
          this.updateNum(res);
        });
      });
    },
    // 购票须知
    toNotice(item) {
      this.showNotice = true;
      item.buyNotice = item.buyNotice
        ? item.buyNotice.replace(/<img/g, '<img style="width: 100%;"')
        : "";
      this.notice = item.buyNotice;
    },
    // 更改预约日期
    updateDate(data, timeArr) {
      if (data) {
        let api = ticketDate;
        if (this.format == 13) {
          api = combinationDate;
        }
        api(data)
          .then(res => {
            this.updateNum(res);
            if (timeArr) {
              this.updateTime(timeArr);
            }
          })
          .catch(() => {});
      } else if (timeArr) {
        this.updateTime(timeArr);
      }
    },
    // 更改预约时段
    updateTime(data) {
      let api = ticketAppointmentDate;
      if (this.format == 13) {
        api = combinationAppointmentDate;
      }
      api(data)
        .then(res => {
          this.updateNum(res);
        })
        .catch(() => {});
    },
    // 批量加购
    updateAddBuyBatch(obj) {
      let api = commonAddBuyBatch;
      api({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.cart = res;
          this.updateNum(res);
        })
        .catch(() => {});
    },
    // 更新快速通行
    updateFast(data) {
      let api = ticketFast;
      if (this.format == 13) {
        api = combinationFast;
      }
      api(data)
        .then(res => {
          this.updateNum(res);
        })
        .catch(() => {});
    },
    // 加购更新
    changeAddBuy(obj) {
      let api = postTicketAddBuy;
      if (this.format == 13) {
        api = combinationAddBuy; //组合
      }
      api({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.cart = res;
          // this.broadcast();
          this.updateNum(res);
        })
        .catch(() => {});
    },
    // 更新游客信息
    updatePlayer(data) {
      this.$store.commit("order/updateCanSubOrder", false);
      let api = ticketTraveller;
      if (this.format == 13) {
        api = combinationTraveller;
      }
      api(data)
        .then(res => {
          this.changeCart(res);
          this.$store.commit("order/updateCanSubOrder", true);
        })
        .catch(() => {});
    },
    // 加载入口
    changeFilter(params) {
      this.params = params;
      // 存在购物车则改时段-改成切换日期直接刷新
      // if (this.cart.id && params.playDate) {
      //   let data = {
      //     cartId: this.cart.id,
      //     playDate: this.params.playDate
      //   };
      //   let time = { ...data }; //时段
      //   delete time.playDate;
      //   time.timeIntervalId = this.params.timeId;
      //   this.updateDate(data, time);
      // } else {
      this.loadData();
      // }
    },
    loadData() {
      this.page = 1;
      let params = {
        subScenicId: this.params.subScenicId,
        categoryId: this.params.categoryId,
        playDate: this.params.playDate
      };
      this.api[this.format]({
        ...params,
        page: this.page,
        size: this.size
      })
        .then(({ records }) => {
          records.forEach(item => {
            item.sellingPrice = item.sellingPrice / 100;
            item.oldPrice = item.sellingPrice;
            item.buyNum = 0;
          });
          this.list = records;
        })
        .catch(() => {});
    },
    // 删除购物车数量
    delItem(obj) {
      obj.type = "-";
      this.$emit("update-cart", obj);
    },
    // 添加到购物车
    updateCart(item, val, index) {
      this.currentIndex = index;
      // 获取产品有效期规则
      const obj = {
        productId: item.productId,
        // playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        subScenicId: this.params.subScenicId,
        buyNum: val
      };
      // 顶部日期有问题 暂时先这样
      if (this.params.timeId) {
        obj.playDate = this.params.playDate;
        obj.timeIntervalId = this.params.timeId;
      }
      this.$emit("update-cart", obj);
    },
    // 更新添加的数量
    updateNum(cart) {
      const { totalProductList, id } = cart;
      this.cart = cart;
      // this.$emit("changeCart", cart);
      this.changeCart(cart); //inject
      this.cartId = id;
      this.list.forEach((item, index) => {
        item.buyNum = 0;
        item.sellingPrice = item.oldPrice;
        for (let temp of totalProductList) {
          if (item.productId === temp.productId) {
            item.buyNum = temp.productFlagPO.buyNum;
            item.sellingPrice = temp.sellingAmount / 100;
            break;
          }
        }
        // 快速下单匹配 -最后一条 this.cart.detailProductList.length - 1
        if (this.cart.detailProductList.length) {
          if (this.cart.detailProductList[0].productId == item.productId) {
            this.currentIndex = index;
          }
        }
      });
      this.$nextTick(() => {
        // 匹配只传当前产品
        cart.detailProductList = cart.detailProductList.filter(
          item => item.productId == this.list[this.currentIndex].productId
        );
        cart.id &&
          this.$refs[`ProductPlayer${this.currentIndex}`][0].updateCart(cart); //传给出游人组件
      });
    }
  }
};
</script>

<style scoped lang="less">
.isPadding {
  padding-bottom: 400px !important;
}
//.门票列表
.ticket-box {
  padding-bottom: 200px;
  .ticket-list {
    width: auto;
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-right: 1px solid transparent;
    }
    .ticket-list-item {
      width: 95%;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      .img {
        width: 190px;
        height: 190px;
        margin-right: 16px;
        border-radius: 10px;
        overflow: hidden;
      }
      .minus-num {
        width: 50px;
        text-align: center;
      }
      .minus {
        font-size: 38px;
      }
      .right-content {
        flex: 1;
        height: 100%;
        .blue {
          color: #f2a925;
          background-color: #fff8e7;
        }
        .custom-price {
          padding: 0 4px;
          border: none;
          width: 50px;
          height: 24px;
          line-height: 24px;
          &:focus {
            background: #eeeeee;
          }
          outline: none;
        }
        .product-setting {
          color: #999999;
          .img {
            width: 7px;
            height: 11px;
          }
        }
        .input-number {
          margin: 0 4px;
          border: none;
          width: 30px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 18px;
          outline-color: #dcdfe6;
          color: #25a2f2;
        }
      }
      .price {
        color: #ff2d2d;
      }
      .fast-key {
        position: absolute;
        top: -16px;
        right: 0;
        .ext {
          min-width: 93px;
          padding: 0 4px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 3px 10px 3px 10px;
          color: #fff;
          cursor: pointer;
        }
        .del {
          .ext;
          background: #ff6767;
        }
        .buy {
          .ext;
          background: #25a2f2;
        }
      }
    }
  }
}
.buy-notice {
  height: 60vh;
  overflow: auto;
}
</style>
