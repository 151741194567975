<!--
  @name: 日期周组件
  @author: 易远胜
  @attrs:
    v-model 选中日期
    dates 展示日期数据
-->
<template>
  <div v-if="appointmentSelection !== 1">
    <!--日期-->
    <div class="dates">
      <ul class="left">
        <li
          v-for="item in dates"
          :key="item"
          @click="clickDate(item)"
          :class="{ active: currentDate === item }"
        >
          <p>{{ weekStr[moment(item).format("d")] }}</p>
          <p class="mt-x">{{ item | dateFilter }}</p>
        </li>
      </ul>
      <div class="line"></div>
      <!--日历-->
      <div class="right">
        <i class="iconfont icon-rili" @click="showCalendar = !showCalendar"></i>
        <div class="calendar" v-show="showCalendar">
          <a-calendar
            :fullscreen="false"
            v-model="currentMoment"
            :disabledDate="disabledDate"
            @select="dateChange"
          />
        </div>
      </div>
    </div>
    <!--时段-->
    <ul class="flex-around time">
      <li
        v-for="item in times"
        :key="item.id"
        class="time-item"
        :class="{ active: timeId === item.id }"
        @click="clickTime(item.id)"
      >
        <p>{{ item.beginTimeFormat }}-{{ item.endTimeFormat }}</p>
        <p class="mt-x font-m">库存：{{ item.remainingStock }}</p>
      </li>

      <li class="disabled" v-show="!times.length && loadedTimes">
        —— 暂无可预约时段，请更换其它日期 ——
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import { merchantSettingOrderInfo } from "../../../../api/merchant";
import { appointmentStockDate } from "../../../../api/product";

export default {
  name: "BuyTime",

  filters: {
    dateFilter(date) {
      let today = moment()
        .startOf("day")
        .valueOf(); // 今天
      let tomorrow = moment()
        .add(1, "day")
        .startOf("day")
        .valueOf(); // 明天
      if (date === today) return "今天";
      else if (date === tomorrow) return "明天";
      else return moment(date).format("M/D");
    }
  },

  props: {
    // 业态
    format: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      moment,
      /** 分时预约选择 1.产品  2.子景区 3.景区 */
      appointmentSelection: 1,
      /** 景区、子景区维度分时预约规则  */
      scenicDpotReservation: [],
      /**下单规则 */
      dates: [], // 日期列表
      startDate: "", // 可售开始日期
      endDate: "", // 可售结束日期
      currentMoment: moment(), // 当前时间moment
      times: [], // 时段
      loadedTimes: false, //是否加载了时段
      appointmentRules: "", // 分时预约规则id
      timeId: "", // 时段Id

      weekStr: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],

      showCalendar: false,
      timer: null // 定时器
    };
  },

  computed: {
    subScenicId() {
      return this.$store.state.common.saleMerchantId;
    },
    // 当前选中时间
    currentDate() {
      return this.currentMoment.valueOf();
    }
  },

  created() {
    this.loadData();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  methods: {
    // 初始化 - 获取景区设置
    async loadData() {
      // 最新业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      const setting = await merchantSettingOrderInfo({
        formatType: this.format
      });
      // console.log(setting);
      if (setting.orderRules.length === 0) {
        this.$error({
          title: "提示",
          content:
            "该景区未配置下单规则，请到后台管理系统的“景区设置”配置或联系管理员！"
        });
        return false;
      }
      // 处理可选日期
      this.loadDate(setting);
      sessionStorage.setItem(
        "orderRules",
        JSON.stringify(setting.orderRules[0])
      ); //下单规则存起来-其他纬度时日期限制
      this.appointmentSelection = setting.appointmentSelection;
      sessionStorage.setItem(
        "appointmentSelection",
        setting.appointmentSelection
      );
      this.scenicDpotReservation = setting.scenicDpotReservation;

      if (setting.appointmentSelection !== 1) {
        // 景区维度 可以从配置获取到分时预约规则id
        if (setting.appointmentSelection === 3) {
          this.appointmentRules =
            setting.scenicDpotReservation[0].appointmentRules;
        }
        // 获取时段列表
        await this.getAppointmentStockDate();
      } else {
        this.change();
      }
      return true;
    },
    // 加载日期 1不限 2 T+N后N天 3：T+N
    loadDate({ correspondOrderRules }) {
      if (correspondOrderRules && correspondOrderRules.length) {
        const obj = correspondOrderRules[0];
        if (obj.orderDateRange == 1) {
          this.startDate = moment()
            .startOf("day")
            .valueOf();
          this.endDate = "";
        } else if (obj.orderDateRange == 2) {
          // 计算可选日期范围
          let t = Number(obj.ttoDay);
          let dates = [];
          for (let i = 0; i <= t; i++) {
            let day = moment()
              .startOf("day")
              .add(+Number(i + obj.tafterDay), "day")
              .valueOf();
            dates.push(day);
          }
          this.startDate = dates[0];
          this.endDate = dates[dates.length - 1];
        } else if (obj.orderDateRange == 3) {
          this.startDate = moment()
            .startOf("day")
            .add(+Number(obj.tafterDay), "day")
            .valueOf();
          this.endDate = "";
        }
      } else {
        this.startDate = moment()
          .startOf("day")
          .valueOf();
        this.endDate = "";
        this.$message.warning(
          '该渠道未配置下单规则，请到"景区后台-设置-景区设置"配置或联系管理员'
        );
      }
      this.currentMoment = moment(this.startDate);
      // 生成快捷日期列表
      this.createDate();
    },
    // 生成快捷日期列表
    createDate() {
      let count = 0;
      // 一天为86400000毫秒
      let endDate = this.endDate || this.currentDate + 86400000 * 6;
      const dates = [this.currentDate];
      while (count < 6 && dates[count] < endDate) {
        count++;
        dates.push(this.currentDate + 86400000 * count);
      }
      // 如果不足7天，且往前推还有时间
      while (count < 6 && dates[0] > this.startDate) {
        count++;
        dates.unshift(dates[0] - 86400000);
      }

      this.dates = dates;
      // console.log(dates);
    },

    // 获取时段列表
    async getAppointmentStockDate() {
      const obj = {
        date: this.currentDate
      };
      // 子景区维度的分时预约需要根据子景区id获取时段
      if (this.appointmentSelection === 2) {
        obj.subScenicId = this.subScenicId;
      }
      // 景区维度则需要根据后台设置选中的规则id获取
      else {
        obj.ruleId = this.appointmentRules; // 规则id
      }
      const data = await appointmentStockDate(obj);
      this.times = data
        .filter(val => val.remainingStock)
        .map(item => ({
          ...item,
          beginTimeFormat: moment(item.beginTime).format("HH:mm"),
          endTimeFormat: moment(item.endTime).format("HH:mm")
        }));

      // 子景区分时预约规则
      if (this.appointmentSelection === 2 && data.length)
        this.appointmentRules = data[0].ruleId;

      this.timeId = this.times[0]?.id || "";
      this.loadedTimes = true;
      console.log(this.loadedTimes);
      this.change();
      // 设置定时，刷新列表
      this.setInterval();
      return data;
    },

    // 选择日期改变
    dateChange(val) {
      this.currentMoment = val;
      this.showCalendar = false;
      this.createDate();
      this.getAppointmentStockDate();
    },
    // 禁用日期可选范围
    disabledDate(currentDate) {
      return (
        currentDate.valueOf() < this.startDate ||
        (this.endDate ? currentDate.valueOf() > this.endDate : false)
      );
    },

    // 点击快捷日期
    clickDate(date) {
      this.currentMoment = moment(date);
      this.getAppointmentStockDate();
    },
    // 点击时段
    clickTime(id) {
      this.timeId = id;
      this.change();
    },

    // 触发日期/时段修改事件
    change() {
      let obj = {
        appointmentRules: this.appointmentRules, // 分时预约规则id
        playDate: this.currentDate, // 游玩日期
        timeId: this.timeId // 时段id
      };
      if (this.appointmentSelection === 1) {
        obj = {};
      }
      this.$emit("change", obj);
    },

    // 设置定时器
    setInterval() {
      if (this.timer) this.clearInterval();
      this.timer = setInterval(() => {
        // 如果存在时段
        if (this.times.length) {
          // 获取第一个时段的结束时分
          const timeItem = this.times[0];
          const [endHour, endMinute] = timeItem.endTimeFormat.split(":");
          // 激活日期的时段的结束时间减5分钟 提前5分钟购票
          const firstEnd = moment(this.currentDate)
            .hour(endHour)
            .minute(endMinute)
            .startOf("second")
            .subtract(5, "minutes")
            .valueOf();
          const now = moment().valueOf();
          // 如果当前时间（时段）大于激活时间，则激活下一个时段
          if (now > firstEnd) {
            // 移除该过期时段
            const removeTime = this.times.shift();
            // 如果后面还有时段
            if (this.times.length) {
              // 如果当前激活时间为过期的时间，则激活下一个时段
              if (removeTime.id === this.timeId) {
                this.clickTime(this.times[0].id);
                this.$message.warning("可预约时段已更新");
              }
            }
          }
        }
        // 如果过了今天可预约时段，则重新加载日期规则，跳转下一天
        else if (
          moment().valueOf() >
          moment(this.dates[0])
            .endOf("day")
            .valueOf()
        ) {
          this.$message.warning("可预约日期已更新");
          this.loadData();
        }
      }, 60000);
    },
    clearInterval() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style lang="less" scoped>
/*日期*/
.dates {
  padding: 16px 0 16px 40px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  background-color: #fff;

  .left {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    font-size: 22px;
    color: #7e7e7e;
    > li {
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
    .active {
      color: #00c150;
    }
  }

  .line {
    margin: 0 36px;
    width: 1px;
    height: 30px;
    background: #d8d8d8;
  }

  .right {
    position: relative;
    text-align: center;
    .icon-rili {
      font-size: 48px;
      padding-right: 36px;
      cursor: pointer;
    }

    .calendar {
      position: absolute;
      right: 0;
      width: 400px;
      z-index: 500;
      border: 1px solid #e5e5e5;
      background: #fff;
    }
  }
}
/*时段*/
.time {
  min-height: 92px;
  overflow-x: auto;
  padding: 16px 40px;
  background: #fff;
  .time-item {
    flex-shrink: 0;
    padding: 0 8px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    color: #7e7e7e;
    &:active {
      opacity: 0.5;
    }
    &.active {
      color: #00c150;
    }
  }
}
</style>
