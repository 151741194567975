<!--
  @name: Header
  @author: zhengjiana
-->
<template>
  <ul class="tabs">
    <li
      v-for="item in tabItems"
      :key="item.type"
      class="tabs-item"
      :class="{ active: value === item.type }"
      @click="input(item.type)"
    >
      {{ item.name }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "BuyTabs",

  components: {},

  model: {
    event: "change"
  },

  props: {
    value: {
      type: Number,
      default: 6
    }
  },

  data() {
    return {
      tabItems: [
        {
          type: 6,
          name: "票务"
        },
        // {
        //   type: 7,
        //   name: "游乐"
        // },
        {
          type: 13,
          name: "组合"
        }
      ]
    };
  },

  methods: {
    input(type) {
      this.$emit("change", type);
    }
  }
};
</script>

<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 55px;
  font-size: 24px;
  text-align: center;
  background: #fff;

  .tabs-item {
    position: relative;
    flex: 1;
    max-width: 30%;
    padding: 30px 0 25px;
    cursor: pointer;
    border-radius: 3px;

    &.active {
      font-size: 28px;
      color: #00c150;
    }
    &.active:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 6px;
      border-radius: 3px;
      background: #00c150;
    }
  }
}
</style>
