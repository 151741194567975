/**
 * MS-产品中心接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/product"; // api前缀

// 【product096】获取分类列表-胡汉杰
export const productMerchantCategoryList = data => {
  return http.get(`${prefix}/product/merchant/category/list`, data);
};

// 【中间层】获取分类列表-炎龙
export const productMerchantCategoryList2 = data => {
  return http.get(`${prefix}/product/merchant/category/list`, data, {
    baseURL: "http://localhost:3333"
  });
};

// 【product105】获取分时预约库存信息-胡汉杰
export const appointmentTimeRuleStock = data => {
  return http.get(`${prefix}/appointment/time/rule/stock`, data);
};

// 获取分时预约库存
export function appointmentStockDate(data) {
  return http.get(`${prefix}/appointment/stock/date`, data);
}
//【product076】获取字段列表-胡汉杰
export function userinfoSettingInfoList(data) {
  return http.get(`${prefix}/userinfo/setting/info/list`, data);
}
