import { orderSelfOrderDetailTicketPrint } from "../../../api/search";
import { printTicket, speak, printProgress } from "../../../utils/hardware";
import { updatePaper } from "../../../utils/global";
import { productPrint } from "../../../api/order";

export default {
  methods: {
    takeNow(ids) {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }

      orderSelfOrderDetailTicketPrint({
        ids,
        templateId: ticketTempId
      })
        .then(res => {
          try {
            const bool = printTicket(JSON.stringify(res));
            if (!bool) {
              speak("打印失败，请联系管理员查看打印机是否连接");
              this.$warning("打印失败，请联系管理员查看打印机是否连接");
              return;
            }
            // 取票成功清空输入框
            if (this.keyWord) this.keyWord = "";
            speak("打印门票中，请勿离开");
            // 更新纸张数
            updatePaper(1, res.length, this);
            productPrint({ idList: ids });
            // 监听打印进度
            printProgress(this.printProgress);
            this.printProcessDialog = true;
            // 显示打印进度弹窗
          } catch (error) {
            this.$error("门票打印失败，请联系管理员");
            speak("门票打印失败，请联系管理员");
          }
        })
        .catch(() => {});
    }
  }
};
