<!--
  @name: 采集人脸组件
  @author: fengyanlong
  @attr: {
    //返回人脸信息id： personId
    v-model:{
      type: String
    }
    echoimgurl: 人脸照片回显
  }
-->
<template>
  <div class="faceInput" :class="{ mini: size }" @click="openCamera">
    <div v-if="imgUrl" class="picture">
      <img :src="imgUrl" alt="图片显示失败" />
    </div>
    <div v-else>
      <div
        class="iconfont icon-renlianheshen icon"
        :class="{ 'mini-icon': size }"
      ></div>
      <div class="tip primary">点击开始采集人脸</div>
    </div>
  </div>
</template>

<script>
import { faceAnalyzeUpload, face } from "../../api/iot";

export default {
  name: "faceInput",
  model: {
    prop: "value"
  },
  props: ["value", "echoimgurl", "size"],
  data() {
    return {
      imgUrl: "",
      imgbase64: ""
    };
  },
  watch: {
    echoimgurl: {
      handler(newVal) {
        if (newVal) {
          this.imgUrl = newVal;
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    openCamera() {
      try {
        window.getPicture = this.getPicture;
        window.test.openCamera();
      } catch (error) {
        this.$message.warning("开启失败，请检查摄像头设备");
      }
    },
    getPicture(data64) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then(res => {
          this.saveFace(res.src);
        })
        .catch(err => {
          console.log(err, "人脸分析失败");
        });
    },
    saveFace(url) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      // if (pid)
      //   postData.lastPersionId = pid;
      // }
      face(postData)
        .then(res => {
          // 1是正常录入成功，2是有疑似重复的人脸，覆盖处理
          // if (res.status == 1) {
          this.imgUrl = url;
          this.$message.success("人脸录入成功");
          this.$emit("input", res.personId);
          this.$emit("update:value", res.personId);
          this.$emit("imgUrl", url);
          // } else {
          //   this.$message.warning("覆盖旧人脸");
          //   this.saveFace(url, res.personId);
          // }
        })
        .catch(() => {
          console.log("人脸录入失败");
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
.mini {
  width: 140px !important;
  height: 140px !important;
}
.faceInput {
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 200px;
  height: 200px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mini-icon {
    line-height: 100px !important;
  }
  .icon {
    line-height: 140px;
    font-size: 90px;
    color: @gray;
  }
  // .tip {
  //   font-size: 18px;
  // }
}
</style>
